import React from "react";
import {
  Page, Text, View, Document, StyleSheet,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import PropTypes from "prop-types";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#fff",
    padding: 30,
  },
  header: {
    textAlign: "center",
    fontSize: 20,
    fontWeight: "bold",
    textTransform: "uppercase",
    borderBottom: 1,
    paddingBottom: 10,
    marginBottom: 20,
  },
  flex: {
    display: "flex",
    flexDirection: "row",
  },
  justifyBetween: {
    justifyContent: "space-between",
  },
  widthKey: {
    width: "20%",
  },
  font: {
    fontSize: 9,
    lineHeight: 1.6,
  },
  rowOdd: {
    fontSize: 9,
    padding: 10,
    backgroundColor: "#E7EBE7",
  },
  rowEven: {
    fontSize: 12,
    padding: 10,
    backgroundColor: "#fff",
  },
  titleChild: {
    paddingLeft: 20,
  },
  row: {
    flexDirection: "column",
  },
  css: (style) => style,
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

const DocPDF = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.header}>
              Surat Rekomendasi
        </Text>
        {
          data && (
            <View>
              <View style={[styles.flex, styles.justifyBetween]}>
                <View style={styles.row}>
                  <View style={[styles.flex, styles.font]}>
                    <Text style={styles.widthKey}>Nomor</Text>
                    <Text>
        :
                      {" "}
                      {data.letter_number}
                    </Text>
                  </View>
                  <View style={[styles.flex, styles.font]}>
                    <Text style={styles.widthKey}>Lapiran</Text>
                    <Text>: 500/1002/PUPR</Text>
                  </View>
                  <View style={[styles.flex, styles.font]}>
                    <Text style={styles.widthKey}>Perihal</Text>
                    <Text>: 500/1002/PUPR</Text>
                  </View>
                </View>
                <View style={styles.font}>
                  <Text>
Pinrang,
                    {" "}
                    {dayjs(data.date).format("DD MMMM YYYY")}
                  </Text>
                </View>
              </View>
              <View style={[styles.font, styles.css({ marginTop: 20 })]}>
                <Text>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Ducimus corrupti iure aut in,
                  alias nobis, sint veniam,
                  incidunt accusamus id illo enim quisquam ipsum maxime earum provident
                  nisi ipsa rem!.
                  Lorem ipsum dolor sit amet
                  consectetur adipisicing elit. Tenetur consectetur provident at cupiditate,
                  obcaecati, ipsa voluptates
                  doloribus aliquam repellat vero nemo eius nesciunt sequi! Pariatur, aliquam et.
                  Reiciendis, numquam veritatis.
                </Text>
              </View>
              <View style={styles.css({
                justifyContent: "flex-end", display: "flex", flexDirection: "row", marginTop: 40,
              })}
              >
                <View style={[styles.font]}>
                  <Text>Kepala Dinas</Text>
                  <View style={styles.css({ marginTop: 40, display: "inline-block" })}>
                    <View>
                      <Text style={styles.css({ fontWeight: "bold", borderBottom: 0.4 })}>{data.functionary.name}</Text>
                    </View>
                    <View style={[styles.flex, styles.font, styles.css({ marginTop: 10 })]}>
                      <Text style={styles.css({ width: "30%" })}>Pangkat</Text>
                      <Text>
:
                        {" "}
                        {data.functionary.position}
                      </Text>
                    </View>
                    <View style={[styles.flex, styles.font]}>
                      <Text style={styles.css({ width: "30%" })}>NIP</Text>
                      <Text>
:
                        {" "}
                        {data.functionary.identification_number}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          )
        }
      </View>
    </Page>
  </Document>
);

DocPDF.propTypes = {
  data: PropTypes.shape.isRequired,
};

export default DocPDF;
