import styled from "styled-components";
import { Button } from "antd";

Button.Blue = styled(Button)`
  background: ${({ theme }) => theme.colorBlue};
  border-color: ${({ theme }) => theme.colorBlue};
  &:hover {
    border-color: ${({ theme }) => theme.colorBlue}dd;
    background: ${({ theme }) => theme.colorBlue}dd;
  }
`;


export default Button;
