import React from "react";
import {
  Page, Text, View, Document, StyleSheet,
} from "@react-pdf/renderer";
import PropTypes from "prop-types";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#fff",
  },
  header: {
    textAlign: "center",
    fontSize: 20,
    fontWeight: "bold",
    textTransform: "uppercase",
    borderBottom: 1,
    paddingBottom: 10,
    marginBottom: 20,
  },
  flex: {
    display: "flex",
    flexDirection: "row",
  },
  widthKey: {
    width: "40%",
  },
  rowOdd: {
    fontSize: 12,
    padding: 10,
    backgroundColor: "#E7EBE7",
  },
  rowEven: {
    fontSize: 12,
    padding: 10,
    backgroundColor: "#fff",
  },
  titleChild: {
    paddingLeft: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

const Doc = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.header}>
          Form Permohonan
        </Text>
        <View>
          {
            data && data.map((item, i) => {
              if (item.child) {
                return (
                  <View>
                    <View style={[styles.flex, i % 2 === 0 ? styles.rowOdd : styles.rowEven]}>
                      <View style={styles.widthKey}>
                        <Text>{item.title}</Text>
                      </View>
                    </View>
                    {
                      item.child.map((itemChild, index) => (
                        <View
                          style={[styles.flex, index % 2 !== 0 ? styles.rowOdd : styles.rowEven]}
                        >
                          <View style={[styles.widthKey, styles.titleChild]}>
                            <Text>{itemChild.title}</Text>
                          </View>
                          <Text>
                            :
                            {" "}
                            {itemChild.content}
                          </Text>
                        </View>
                      ))
                    }
                  </View>
                );
              }
              return (
                <View style={[styles.flex, i % 2 === 0 ? styles.rowOdd : styles.rowEven]}>
                  <View style={styles.widthKey}>
                    <Text>{item.title}</Text>
                  </View>
                  <Text>
                    :
                    {" "}
                    {item.content}
                  </Text>
                </View>
              );
            })
          }
        </View>
      </View>
    </Page>
  </Document>
);

Doc.propTypes = {
  data: PropTypes.shape.isRequired,
};

export default Doc;
