import React from "react";
import PropTypes from "prop-types";

const Users = ({ children }) => (
  <div>
    {children}
  </div>
);

Users.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Users;
