/* eslint-disable no-unused-expressions */
import React, { useState } from "react";
import PropTypes from "prop-types";

export const AllotmentContext = React.createContext({});

const AllotmentContextProvider = ({ children }) => {
  const [drawable, setDrawable] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [drawCancel, setDrawCancel] = useState(false);
  const [coordinates, setCoordinates] = useState(null);
  const [areas, setAreas] = useState(null);
  const [properties, setProperties] = useState(null);

  const handleModalVisible = (visible, cb) => {
    setModalVisible(visible);
    cb && cb();
  };

  return (
    <AllotmentContext.Provider value={{
      drawable,
      modalVisible,
      areas,
      properties,
      setProperties,
      setAreas,
      coordinates,
      setCoordinates,
      drawCancel,
      setDrawCancel,
      setModalVisible: handleModalVisible,
      setDrawable,
    }}
    >
      {children}
    </AllotmentContext.Provider>
  );
};

AllotmentContextProvider.propTypes = {
  children: PropTypes.shape.isRequired,
};

export default AllotmentContextProvider;
