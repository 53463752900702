/* eslint-disable react/prop-types */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import {
  Card, Flex, Skeleton, DocRequest,
} from "components";
import { DocStyled } from "components/utils";
import { Link } from "@reach/router";
import {
  Button,
} from "antd";
import {
  Page, Text, View, Document, StyleSheet, BlobProvider,
} from "@react-pdf/renderer";
import PropTypes from "prop-types";
import {
  globalFetch,
  ADMIN_REQUEST,
} from "utils/api";
import { transformData } from "utils";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#fff",
  },
  header: {
    textAlign: "center",
    fontSize: 20,
    fontWeight: "bold",
    textTransform: "uppercase",
    borderBottom: 1,
    paddingBottom: 10,
    marginBottom: 20,
  },
  flex: {
    display: "flex",
    flexDirection: "row",
  },
  widthKey: {
    width: "40%",
  },
  rowOdd: {
    fontSize: 12,
    padding: 10,
    backgroundColor: "#E7EBE7",
  },
  rowEven: {
    fontSize: 12,
    padding: 10,
    backgroundColor: "#fff",
  },
  titleChild: {
    paddingLeft: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

const Doc = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.header}>
          Form Permohonan
        </Text>
        <View>
          {
            data && data.map((item, i) => {
              if (item.child) {
                return (
                  <View>
                    <View style={[styles.flex, i % 2 === 0 ? styles.rowOdd : styles.rowEven]}>
                      <View style={styles.widthKey}>
                        <Text>{item.title}</Text>
                      </View>
                    </View>
                    {
                      item.child.map((itemChild, index) => (
                        <View style={
                          [styles.flex, index % 2 !== 0 ? styles.rowOdd : styles.rowEven]
                        }
                        >
                          <View style={[styles.widthKey, styles.titleChild]}>
                            <Text>{itemChild.title}</Text>
                          </View>
                          <Text>
                            :
                            {itemChild.content}
                          </Text>
                        </View>
                      ))
                    }
                  </View>
                );
              }
              return (
                <View style={[styles.flex, i % 2 === 0 ? styles.rowOdd : styles.rowEven]}>
                  <View style={styles.widthKey}>
                    <Text>{item.title}</Text>
                  </View>
                  <Text>
                      :
                    {item.content}
                  </Text>
                </View>
              );
            })
          }
        </View>
      </View>
    </Page>
  </Document>
);

Doc.propTypes = {
  data: PropTypes.shape.isRequired,
};

const ListItem = ({ title, value }) => (
  <div style={{
    display: "flex",
  }}
  >
    <span style={{ flexBasis: "40%", fontWeight: !value && "bold" }}>{title}</span>
    {
      value && (
        <span>
          {value}
        </span>
      )
    }
  </div>
);

const Request = ({ id }) => {
  const [data, setData] = useState(null);
  const [dataRecLetter, setDataRecLetter] = useState(null);

  useEffect(() => {
    globalFetch({ path: `${ADMIN_REQUEST}/${id}` })
      .then((_data) => {
        let newObj = [];
        const recReq = _data.recommendationRequest;
        setDataRecLetter(_data.recommendationRequest.recommendation_letter);

        newObj = transformData(recReq);

        setData(newObj);
      });
  }, []);

  const isRecLetter = dataRecLetter ? dataRecLetter.id : "generate";

  return (
    <BlobProvider document={<DocRequest data={data} />}>
      {({ url }) => (
        <Card
          title="Detail Permohonan"
          extra={(
            <Flex gutter={8} alignItems="center">
              <div>
                <Link to={`/admin/permohonan/${id}/rekomendasi/${isRecLetter}`}>
                  <Button size="large" type="primary">Surat Rekomendasi</Button>
                </Link>
              </div>
              <div>
                <a href={url} target="_blank" rel="noopener noreferrer">
                  <Button size="large" type="primary">
                      Cetak Permohonan
                  </Button>
                </a>
              </div>
            </Flex>
            )}
          style={{ padding: 24 }}
        >
          <DocStyled>
            <ul>
              {
                  data && data.map((item) => {
                    if (item.child) {
                      return (
                        <li>
                          <ul>
                            <span>{item.title}</span>
                            {
                              item.child.map((itemChild) => (
                                <li>
                                  <ListItem title={itemChild.title} value={itemChild.content} />
                                </li>
                              ))
                            }
                          </ul>
                        </li>
                      );
                    }
                    return (
                      <li>
                        <ListItem title={item.title} value={item.content} />
                      </li>
                    );
                  })
                }
            </ul>
            {
              !data && (
                <div>
                  <Skeleton height={20} marginBottom={20} />
                  <Skeleton height={20} marginBottom={20} />
                  <Skeleton height={20} marginBottom={20} />
                </div>
              )
            }
          </DocStyled>
        </Card>
      )}
    </BlobProvider>
  );
};

Request.propTypes = {

};

export default Request;
