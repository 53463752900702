import styled from "styled-components";
import { Form } from "antd";
import { maxSM } from "utils";

export const MakeRequestStyled = styled.div`
  max-width: 486px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000029;
`;

export const Selection = styled.div`
  padding: 20px;
  h3 {
    font-size: 38px;
    margin: 0;
    font-weight: bold;
  }
`;

export const FormAddtional = styled.div`
  padding: 20px;
  border-top: 1px solid #cbcbcb;
  h4 {
    font-size: 16px;
    font-weight: bold;
  }
  .ant-form-item {
    display: flex;
    align-items: center;
  }
  .ant-form-item-label {
    ${maxSM} {
      line-height: 1.5;
      label {
        white-space: normal;
      }
    }
  }
`;