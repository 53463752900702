import styled from "styled-components";
import { Layout } from "antd";
import { maxSM } from "utils";

const { Content } = Layout;

export const ContentStyled = styled(Content)`
  padding: 50px;
   &&& {
    ${maxSM} {
      padding: 0 !important;
    }
   }
`;

export const ContentInner = styled.div`
  padding: 24px;
  min-height: 280px;
  ${maxSM} {
    padding: 0;
  }
`;
