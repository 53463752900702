/* eslint-disable import/no-unresolved */
import React from "react";
import AllotmentContextProvider from "contexts/allotment-context";
import PropTypes from "prop-types";

const Admin = ({ children }) => (
  <AllotmentContextProvider>
    {children}
  </AllotmentContextProvider>
);

Admin.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Admin;
