import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Flex from "../flex";

const DSItem = styled.div`
  padding: 10px 20px;
  text-align: center;
  h4 {
    margin: 0;
    color: #333;
    font-weight: bold;
  }
`;

const DocumentStatus = ({ style }) => (
  <div style={{
    position: "absolute",
    bottom: 0,
    ...style,
  }}
  >
    <Flex>
      <div>
        <DSItem>
          <h4>1 Juta</h4>
          <p>Permohonan</p>
        </DSItem>
      </div>
      <div>
        <DSItem>
          <h4>800 K</h4>
          <p>Diterima</p>
        </DSItem>
      </div>
      <div>
        <DSItem>
          <h4>200 K</h4>
          <p>Ditolak</p>
        </DSItem>
      </div>
    </Flex>
  </div>
);

DocumentStatus.propTypes = {
  style: PropTypes.shape.isRequired,
};

export default DocumentStatus;
