/* eslint-disable react/prop-types */
import React, { useRef, useState, useEffect } from "react";
import {
  Button, Radio, Carousel, Form, Modal,
} from "antd";
import { navigate } from "@reach/router";
import { Flex } from "components";
import { maxSM } from "utils";
import {
  globalFetch, SURVEYOR_REC_REQUEST, globalPost, SURVEYOR_SURVEY_REC_REQUEST,
} from "utils/api";
import {
  Card, Footer, InputStyled, RadioGroup, RadioDanger, CarouselItem,
} from "../styled";


const ListItem = ({ title, value }) => (
  <div style={{ display: "flex" }}>
    <span style={{ flexBasis: "50%" }}>{title}</span>
    :
    {" "}
    <span>{value}</span>
  </div>
);

const FormItem = ({ label, children }) => (
  <div style={{ marginBottom: 10 }}>
    <Flex alignItems="center" justifyContent="center" gutter={10}>
      <div style={{ flexBasis: "50%" }}>
        <span style={{ fontSize: 12 }}>{label}</span>
      </div>
      <div style={{ flexBasis: "50%" }}>
        {children}
      </div>
    </Flex>
  </div>
);

const Evaluation = ({ id, form }) => {
  const carouselRef = useRef(null);
  const [current, setCurrent] = useState(1);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    globalFetch({ path: `${SURVEYOR_REC_REQUEST}/${id}` })
      .then((_data) => {
        setData(_data.recommendationRequest);
      });
  }, []);

  const handleNext = () => {
    form.validateFields((err) => {
      if (!err) {
        carouselRef.current.next();
        if (current <= 4) {
          setCurrent(current + 1);
        }
      }
    });
  };

  const handlePrev = () => {
    carouselRef.current.prev();
    if (current > 1) {
      setCurrent(current - 1);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        setLoading(true);
        let newObj = {
          outputs: {},
        };

        const transformObj = (key, name) => {
          const field = key.split("-")[1];
          newObj = {
            ...newObj,
            [name]: {
              ...newObj[name],
              [field]: values[key],
            },
          };
        };

        newObj.is_approved = values.is_approved;
        // newObj.recommendation_request_id = id;
        newObj.field_data = {
          ...newObj.field_data,
          allotment_id: data.allotment_id,
        };

        Object.keys(values).map((key) => {
          if (/(outputs)/g.test(key)) {
            transformObj(key, "outputs");
          }

          if (/(building_components)/g.test(key)) {
            transformObj(key, "building_components");
          }

          if (/(field_data)/g.test(key)) {
            transformObj(key, "field_data");
          }

          if (/(summaries)/g.test(key)) {
            transformObj(key, "summaries");
          }
        });

        globalPost({ path: `${SURVEYOR_SURVEY_REC_REQUEST}/${id}/submitSurvey`, body: JSON.stringify(newObj), headers: { "Content-Type": "application/json" } })
          .then(() => {
            Modal.info({
              title: "Survey Berhasil",
              content: (
                <div>
                  <p>Survey Anda telah berhasil dikirim</p>
                </div>
              ),
              onOk() {
                navigate("/surveyor");
              },
            });
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    });
  };

  const { getFieldDecorator } = form;

  return (
    <Form onSubmit={handleSubmit}>
      <Flex alignItems="center" justifyContent="space-between" style={{ marginBottom: 20 }}>
        <div>
          <h3 style={{ fontWeight: "bold", fontSize: 20, margin: 0 }}>Daftar Pemohon</h3>
        </div>
        <div>
          <span>
            <span style={{ fontSize: 20 }}>{current}</span>
            / 4
          </span>
        </div>
      </Flex>
      <Carousel
        adaptiveHeight
        dots={false}
        infinite={false}
        focusOnSelect
        ref={carouselRef}
      >
        <CarouselItem>
          <Card>
            <h4>Input</h4>
            <div>
              <ul>
                <li>
                  <ListItem title="No. Pendaftaran" value={data && data.land_certificate_number} />
                </li>
                <li>
                  <ListItem title="Nama" value={data && data.requestor_name} />
                </li>
                <li>
                  <ListItem title="Alamat" value={data && data.address} />
                </li>
                <li>
                  <ListItem title="No. Telepon" value={data && data.no_telepon} />
                </li>
                <li>
                  <span>Lokasi</span>
                  <ul>
                    <li>
                      <ListItem title="Lat" value={data && data.latitude} />
                    </li>
                    <li>
                      <ListItem title="Long" value={data && data.longitude} />
                    </li>
                  </ul>
                </li>
                <li>
                  <ListItem title="No. Sertifikat Tanah" value={data && data.land_certificate_number} />
                </li>
                <li>
                  <span>Usulan Permohonan</span>
                  <ul>
                    <li>
                      <ListItem title="Usulan Peruntukan" value={data && data.requested_activity} />
                    </li>
                    <li>
                      <ListItem title="Luas Tanah" value={data && data.land_area} />
                    </li>
                    <li>
                      <span>Luas Bangunan</span>
                      <ul>
                        {
                          data && data.request_floor_areas.map((item, i) => (
                            <li>
                              <ListItem title={`Luas Tanah ${i + 1}`} value={item.area} />
                            </li>
                          ))
                        }
                      </ul>
                    </li>
                    <li>
                      <ListItem title="Tinggi Bangunan" value={data && data.building_height} />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </Card>
          <Card>
            <h4>Output</h4>
            <div>
              <FormItem label="Kesesuaian">
                {getFieldDecorator("outputs-kesesuaian")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="Kuota bangunan Tersisa">
                {getFieldDecorator("outputs-kuota_bangunan_tersisa")(
                  <InputStyled />,
                )}
              </FormItem>
            </div>
          </Card>
        </CarouselItem>
        <CarouselItem>
          <Card>
            <h4>Data Lapangan</h4>
            <div>
              <FormItem label="Bukti Kuasa Lahan">
                {getFieldDecorator("field_data-bukti_kuasa_lahan")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="Sebelah Utara">
                {getFieldDecorator("field_data-sebelah_utara")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="Sebelah Selatan">
                {getFieldDecorator("field_data-sebelah_selatan")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="Sebelah Timur">
                {getFieldDecorator("field_data-sebelah_timur")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="Sebelah Barat">
                {getFieldDecorator("field_data-sebelah_barat")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="Lebar Jalan Eksisting">
                {getFieldDecorator("field_data-lebar_jalan_eksisting")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="Konstruksi Jalan">
                {getFieldDecorator("field_data-konstruksi_jalan")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="Berdasar ROW Planning">
                {getFieldDecorator("field_data-berdasar_row_planning")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="Fisik Terbangun">
                {getFieldDecorator("field_data-fisik_terbangun")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="GSP Depan">
                {getFieldDecorator("field_data-gsp_depan")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="GSP Samping">
                {getFieldDecorator("field_data-gsp_samping")(
                  <InputStyled />,
                )}
              </FormItem>
            </div>
          </Card>
        </CarouselItem>
        <CarouselItem>
          <Card>
            <h4>Data Lapangan</h4>
            <div>
              <FormItem label="GSB Depan">
                {getFieldDecorator("field_data-gsb_depan")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="GSB Samping">
                {getFieldDecorator("field_data-gsb_samping")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="GS Sungai">
                {getFieldDecorator("field_data-gs_sungai")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="GS Setu">
                {getFieldDecorator("field_data-gs_setu")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="GS Sutet">
                {getFieldDecorator("field_data-gs_sutet")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="KDB">
                {getFieldDecorator("field_data-kdb")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="KLB">
                {getFieldDecorator("field_data-klb")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="Jumlah Lantai">
                {getFieldDecorator("field_data-jumlah_lantai")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="Lebar Bentangan">
                {getFieldDecorator("field_data-lebar_bentangan")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="Bukti Milik Tanah">
                {getFieldDecorator("field_data-bukti_milik_tanah")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="Nomor Bukti Milik Tanah">
                {getFieldDecorator("field_data-nomor_bukti_milik_tanah")(
                  <InputStyled />,
                )}
              </FormItem>
            </div>
          </Card>
        </CarouselItem>
        <CarouselItem>
          <Card>
            <h4>Komponen Bangunan</h4>
            <div>
              <FormItem label="Pondasi">
                {getFieldDecorator("building_components-pondasi")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="Lantai">
                {getFieldDecorator("building_components-lantai")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="Dinding">
                {getFieldDecorator("building_components-dinding")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="Rangka Atap">
                {getFieldDecorator("building_components-rangka_atap")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="Penutup Atap">
                {getFieldDecorator("building_components-penutup_atap")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="Ketinggian Bangunan">
                {getFieldDecorator("building_components-ketinggian_bangunan")(
                  <InputStyled />,
                )}
              </FormItem>
            </div>
          </Card>
          <Card>
            <h4>Summary</h4>
            <div>
              <FormItem label="Luas Bangunan Terhadap Kondisi Eksisting">
                {getFieldDecorator("summaries-luas_bangunan_terhadap_kondisi_eksisting")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="Row Planning Terhadap Kondisi Eksisting">
                {getFieldDecorator("summaries-row_planning_terhadap_kondisi_eksisting")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="GSP">
                {getFieldDecorator("summaries-gsp")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="GSB">
                {getFieldDecorator("summaries-gsb")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="KDB">
                {getFieldDecorator("summaries-kdb")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="KLB">
                {getFieldDecorator("summaries-klb")(
                  <InputStyled />,
                )}
              </FormItem>
              <FormItem label="catatan">
                {getFieldDecorator("summaries-catatan")(
                  <InputStyled.TextArea />,
                )}
              </FormItem>
            </div>
          </Card>
          <Card>
            <h4>Status</h4>
            <div>
              {getFieldDecorator("is_approved")(
                <RadioGroup buttonStyle="solid">
                  <RadioDanger value="0">Ditolak</RadioDanger>
                  <Radio.Button value="1">Diterima</Radio.Button>
                </RadioGroup>,
              )}
            </div>
          </Card>
        </CarouselItem>
      </Carousel>
      <Footer>
        <Flex gutter={10}>
          <div style={{ flexBasis: "40%" }}>
            <Button onClick={handlePrev} block>Kembali</Button>
          </div>
          <div style={{ flexBasis: "60%" }}>
            {
              current === 4 ? (
                <Button loading={loading} block htmlType="submit" type="primary">Kirim</Button>
              ) : (
                <Button onClick={handleNext} block type="primary">Selanjutnya</Button>
              )
            }
          </div>
        </Flex>
      </Footer>
    </Form>
  );
};

export default Form.create({ name: "evaluasi" })(Evaluation);
