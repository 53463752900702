import React, { useEffect, useState, useContext } from "react";
import { Maps, MakeRequest } from "components";
import styled from "styled-components";
import { RequestContext } from "contexts/request-context";
import { CITIZEN_ALLOTMENT_AREA, globalFetch } from "utils/api";

const MakeRequestStyled = styled(MakeRequest)`
  position: fixed;
  top: 20px;
  z-index: 10;
  background: #fff;
  left: 20px;
  bottom: ${({ expand }) => (expand ? "20px" : "initial")};
  overflow-x: auto;
`;

const SubmitRequest = () => {
  const [dataArea, setDataArea] = useState(null);
  const [centerArea, setCenterArea] = useState(null);
  const {
    idArea, setExpand, expand, coordinate, setCoordinate, prperties, setProperties,
  } = useContext(RequestContext);

  useEffect(() => {
    if (idArea) {
      globalFetch({ path: `${CITIZEN_ALLOTMENT_AREA}?allotment_id=${idArea}` })
        .then((_data) => {
          const newAreas = _data.allotmentAreas.map((item) => {
            const { coordinates, ...props } = item;
            const newItem = {
              type: "Feature",
              geometry: {
                type: "GeometryCollection",
                geometries: [
                  { type: "Polygon", coordinates: [coordinates.map((item) => [item.latitude, item.longitude])] },
                ],
              },
              properties: {
                ...props,
              },
            };
            return newItem;
          });

          const currentArea = newAreas[0];
          setCenterArea(currentArea);
          setDataArea(newAreas);
        });
    }
  }, [idArea]);

  return (
    <>
      <MakeRequestStyled expand={expand} />
      <Maps
        isRequest
        idArea={idArea}
        drawable={null}
        isEdit={null}
        handleDrawable={null}
        handleExpand={setExpand}
        drawCancel={null}
        centerArea={centerArea}
        handleDrawCancel={null}
        handleProperties={setProperties}
        modalVisible={null}
        center={{ coordinate, setCoordinate }}
        handleModalVisible={null}
        dataFeatures={dataArea && dataArea}
        handleCoordinates={null}
      />
    </>
  );
};

export default SubmitRequest;
