import React from "react";
import {
  Layout, Menu,
} from "antd";
import { Link } from "@reach/router";
import DocumentStatus from "../document-status";
import { MenuItem } from "./styled";

const { Sider } = Layout;

const Sidebar = () => (
  <Sider width={200} style={{ background: "#fff" }}>
    <Menu
      mode="inline"
      defaultSelectedKeys={["1"]}
      defaultOpenKeys={["sub1"]}
      style={{ height: "calc(100vh - 164px)", borderRight: "transparent" }}
    >
      <MenuItem key="1">
        <Link to="/citizen">Beranda</Link>
      </MenuItem>
      <MenuItem key="2">
        <Link to="/citizen/proses">Proses</Link>
      </MenuItem>
      <MenuItem key="3">
        <Link to="/citizen/permohonan">Permohonan</Link>
      </MenuItem>
      <MenuItem key="4">
        <Link to="/citizen/permohonan/new">Ajukan Permohonan</Link>
      </MenuItem>
    </Menu>
    <DocumentStatus />
  </Sider>
);

export default Sidebar;
