import React from "react";
import PropTypes from "prop-types";

const Allotments = ({ children }) => (
  <div>
    {children}
  </div>
);

Allotments.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Allotments;
