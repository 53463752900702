import React from "react";
import { BannerHero } from "components/utils";
import { UserContentWrap } from "../styled";

const Home = () => (
  <div>
    <UserContentWrap>
      <BannerHero />
    </UserContentWrap>
  </div>
);

export default Home;
