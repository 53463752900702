import { getToken, redirect, removeCookie } from ".";

// export const HOST = process.env.NODE_ENV === "production" ? process.env.HOST_PROD : process.env.HOST_BETA;
// const VERSION = "/v1";
export const HOST = "https://api.adviceplanning.pinrangkab.go.id/api/v1";

export const LOGIN = "/auth/login";
export const LOGOUT = "/auth/logout";
export const ALLOTMENTS = "/admin/allotment";
export const ALLOTMENTS_AREA = "/admin/allotment_area";
export const REC_REQUEST = "/citizen/recommendation_request";
export const CITIZEN_ALLOTMENT = "/citizen/allotment";
export const CITIZEN_ALLOTMENT_AREA = "/citizen/allotment_area";
export const CITIZEN_REGISTER = "/citizen/register";
export const CITIZEN_REC_REQUEST = "/citizen/recommendation_request";
export const SURVEYOR_REC_REQUEST = "/surveyor/recommendation_request";
export const SURVEYOR_SURVEY_REC_REQUEST = "/surveyor/survey/recommendation_request";
export const ADMIN_USER = "/admin/user";
export const ADMIN_ROLES = "/admin/user/getRoleOptions";
export const ADMIN_REQUEST = "/admin/recommendation_request";
export const ADMIN_FUNCTIONARY = "/admin/functionary";
export const ADMIN_REC_REQUEST = "/admin/recommendation_letter";
export const ADMIN_REC_LETTER = "/admin/recommendation_letter";

const AUTH = `Bearer ${getToken()}`;

export const fetchWithParam = (url, param) => fetch(`${HOST}${url}${param}`, {
  headers: {
    Authorization: AUTH,
    Accept: "application/json",
  },
}).then((res) => {
  if (res.status === 401) {
    removeCookie();
    window.location.href = "/login";
  }
  return res.json();
});

export const multiFetch = (path) => new Promise((resolve, reject) => Promise.all(path)
  .then((values) => Promise.all(values.map((value) => value.json())))
  .then((data) => {
    redirect(data.diagnostic.status, "/login", resolve(data));
  })
  .catch((err) => {
    reject(err);
  }));

export const globalFetch = ({ method = "GET", path }) => new Promise((resolve, reject) => {
  fetch(`${HOST}${path}`, {
    method,
    headers: {
      Authorization: AUTH,
      Accept: "application/json",
    },
  })
    .then((res) => {
      if (!res.ok) {
        redirect(res.status, "/login");
        reject(res);
      }
      return res.json();
    })
    .then((data) => {
      resolve(data);
    })
    .catch((err) => {
      reject(err);
    });
});

export const globalPost = ({
  method = "POST", path, headers, body,
}) => new Promise((resolve, reject) => {
  fetch(`${HOST}${path}`, {
    method,
    headers: {
      Authorization: AUTH,
      Accept: "application/json",
      ...headers,
    },
    body,
  })
    .then((res) => {
      if (!res.ok) {
        redirect(res.status, "/login");
        reject(res);
      }
      return res.json();
    })
    .then((data) => {
      resolve(data);
    })
    .catch((err) => {
      reject(err);
    });
});
