/* eslint-disable no-shadow */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useContext } from "react";
import {
  Card, Flex, Skeleton, SEO,
} from "components";
import {
  Button, Input, Modal, Table, Typography,
} from "antd";
import doc from "images/document.png";
import { Link } from "@reach/router";
import { tableNoBorder } from "components/utils";
import { AllotmentContext } from "contexts/allotment-context";
import PropTypes from "prop-types";
import {
  ALLOTMENTS_AREA, ALLOTMENTS, globalFetch, globalPost,
} from "utils/api";

const { Paragraph } = Typography;

const Allotment = ({ id }) => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const { areas, setAreas } = useContext(AllotmentContext);

  useEffect(() => {
    setLoading(true);
    globalFetch({ path: `${ALLOTMENTS}/${id}` })
      .then(() => {
        setLoading(false);
        setData(data.allotment);
      });
  }, []);

  useEffect(() => {
    globalFetch({ path: `${ALLOTMENTS_AREA}?allotment_id=${id}` })
      .then(() => {
        setLoading(false);
        setAreas(data.allotmentAreas);
      });
  }, [refetch]);

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Hapus Area Peruntukan",
      content: "Apakah kamu yakin ingin menghapus area ini?",
      okText: "Ya",
      okType: "danger",
      cancelText: "Tidak",
      onOk() {
        const formData = new FormData();
        formData.append("_method", "DELETE");
        setLoadingDelete(true);
        globalPost({ path: `${ALLOTMENTS_AREA}/${id}`, body: formData })
          .then(() => {
            setLoadingDelete(false);
            setRefetch(!refetch);
          });
      },
    });
  };

  const columns = [
    {
      title: "Nama",
      dataIndex: "id",
      key: "id",
      render: (val) => (
        <div>
Area
          {" "}
          {val}
        </div>
      ),
    },
    {
      title: "KBD Maksimal",
      dataIndex: "kbd_max",
      key: "kbd_max",
    },
    {
      title: "KLB Maksimal",
      dataIndex: "klb_max",
      key: "klb_max",
    },
    {
      title: "KDH Minimun",
      dataIndex: "kdh_min",
      key: "kdh_min",
    },
    {
      title: "Max Jumalh Lantai",
      dataIndex: "max_floor",
      key: "max_floor",
    },
    {
      title: "Keterangan",
      dataIndex: "information",
      key: "information",
      render: (val) => (
        <Paragraph style={{ margin: 0 }} ellipsis>{val}</Paragraph>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 180,
      align: "right",
      render: (_, values) => (
        <Flex justifyContent="flex-end" gutter={8}>
          <div>
            <Link to={`/admin/peruntukan/area/${id}/edit/${values.id}`}>
              <Button>Edit</Button>
            </Link>
          </div>
          <div>
            <Button type="danger" loading={loadingDelete} onClick={() => handleDelete(values.id)}>Hapus</Button>
          </div>
        </Flex>
      ),
    },
  ];

  const handleSubmit = () => {

  };

  return (
    <Card
      title={data && data.name}
      extra={(
        <Link to={`/admin/peruntukan/area/${id}/new`}>
          <Button block type="primary" size="large" style={{ textTransform: "uppercase" }}>Tambah Area</Button>
        </Link>
      )}
      style={{ padding: 24 }}
    >
      <SEO title="Area" />
      {
        areas && (
          <div>
            <h4 style={{ marginBottom: 20, fontSize: 24 }}>Daftar Area</h4>
            <Table
              css={tableNoBorder}
              loading={loading}
              dataSource={areas}
              columns={columns}
              scroll={{ x: 1000 }}
            />
          </div>
        )
      }
      {
        loading && (
          <div>
            <Skeleton height={20} marginBottom={20} />
            <Skeleton height={20} marginBottom={20} />
            <Skeleton height={20} marginBottom={20} />
          </div>
        )
      }
      {
        areas && !areas.length ? (
          <Flex justifyContent="center" style={{ height: "50vh" }} alignItems="center">
            <div style={{ textAlign: "center" }}>
              <img src={doc} alt="" />
              <p style={{ marginTop: 20 }}>
                Belum ada data area untuk peruntukan “
                {data && data.name}
                ”
              </p>
              <Link to={`/admin/peruntukan/area/${id}/new`}>
                <Button onClick={() => setVisible(true)} type="primary" size="large" style={{ marginTop: 20, textTransform: "uppercase" }}>Tambah Area</Button>
              </Link>
            </div>
          </Flex>
        ) : null
      }
      <Modal
        title="Tambah Peruntukan"
        visible={visible}
        footer={null}
        onOk={handleSubmit}
        onCancel={() => setVisible(false)}
      >
        <form>
          <Input placeholder="Nama Peruntukan" />
          <div>
            <Button block type="primary" size="large" style={{ marginTop: 20, textTransform: "uppercase" }}>Simpan</Button>
          </div>
        </form>
      </Modal>
    </Card>
  );
};

Allotment.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Allotment;
