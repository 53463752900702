import React from "react";
import { Router } from "@reach/router";
import {
  User, Home, Login, Process, Request, RequestDetail, RecommendationRequest,
  Surveyor, RequestList, TrackMaps,
  RequestEvaluation, Admin, Allotments, AllotmentList, Allotment, AllotmentMaps,
  AdminRequest, Landing,
  AdminUsers, AdminUserCitizen, AdminRequestList, AdminFunctionaries, AdminFunctionary,
  AdminRequestRecommendation,
} from "containers";
import { UnderConstruction } from "components";

const RouterApp = () => (
  <Router>
    <Landing path="/" />
    <User path="citizen">
      <Home path="/" />
      <Process path="proses" />
      <Request path="permohonan" />
      <RequestDetail path="permohonan/:id" />
      <RecommendationRequest path="permohonan/new" />
    </User>
    <Surveyor path="surveyor">
      <RequestList path="/" />
      <RequestEvaluation path="/:id/evaluasi" />
      <TrackMaps path="/:id/maps" />
    </Surveyor>
    <Admin path="admin">
      <UnderConstruction path="/" />
      <Allotments path="peruntukan">
        <AllotmentList path="/" />
        <Allotment path="/:id" />
        <AllotmentMaps path="area/:id/new" />
        <AllotmentMaps path="area/:id/:type/:edit" />
      </Allotments>
      <AdminRequest path="permohonan/:id" />
      <AdminRequestList path="permohonan" />
      <AdminRequestRecommendation path="permohonan/:id/rekomendasi/:idLetter" />
      <AdminUsers path="user">
        {/* <AdminUserSurveyor path="surveyor" /> */}
        <UnderConstruction path="surveyor" />
        <AdminUserCitizen path="citizen" />
      </AdminUsers>
      <AdminFunctionaries path="pejabat">
        <AdminFunctionary path="/" />
      </AdminFunctionaries>
    </Admin>
    <Login path="login" />
    <Login path="signup" />
  </Router>
);

export default RouterApp;
