import styled from "styled-components";
import { maxSM } from "utils";

export const Nav = styled.nav`
  ${maxSM} {
    height: 108px;
  }
`;

export const MenuItem = styled.div`
  a {
    text-transform: uppercase;
    font-weight: bold;
  }
`;

export const NavLink = styled.a`
  ${maxSM} {
    padding: 5px 0px;
    display: block;
    color: #fff;
  }
`;

export const Banner = styled.div`
  &:after {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    content: " ";
    width: 40%;
    background: url("${({ bg }) => bg}");
    background-size: cover;
    height: 360px;
    top: 50%;
    transform: translateY(-50%);
    ${maxSM} {
      display: none;
    }
  }
  &:before {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    content: " ";
    width: 40%;
    background: #4caf5069;
    height: 360px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    ${maxSM} {
      display: none;
    }
  }
`;

export const ProcessStyled = styled.div`
  padding: 100px 0;
  background: #EDF4F0;
  margin-top: 160px;
  position: relative;
  &:after {
    position: absolute;
    height: 480px;
    top: -60PX;
    bottom: 0;
    background: #EDF4F0;
    left: 0;
    right: 0;
    transform: skew(0deg, 4deg);
    content: " ";
    z-index: -1;
  }
`;

export const ProcessTitle = styled.h3`
  font-size: 26px;
  font-weight: bold;
`;

export const Footer = styled.footer`
  background: #006528;
  padding: 60px 0;
  color: #ffffff7a;
  h4 {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
  }
  p {
    margin-top: 20px;
  }
  a {
    color: #ffffff7a;
  }
`;
