/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import {
  Container, Flex, Logo, DocumentStatus, Avatar,
} from "components";
import { Link } from "@reach/router";
import { BannerHero, Process } from "components/utils";
import { getToken, getRole, maxSM } from "utils";

import { Row, Col } from "antd";
import bg from "images/bg-login.jpg";
import {
  Nav, MenuItem, Banner, ProcessStyled, ProcessTitle, Footer, NavLink,
} from "./styled";

const Landing = () => {
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  return (
    <div>
      <Nav>
        <Container style={{ maxWidth: 992 }}>
          <Flex alignItems="center" justifyContent="space-between" style={{ height: 68 }}>
            <div>
              <Logo />
            </div>
            <div>
              <Flex
                gutter={20}
                alignItems="center"
                css={`
                ${maxSM} {
                  position: absolute;
                  right: 0;
                  left: 0;
                  justify-content: space-between;
                  top: 60px;
                  background: #4CAF50;
                  padding: 8px 16px;
                  z-index: 999;
                }
              `}
              >
                <MenuItem toggle={toggle}>
                  <NavLink href="#">Home</NavLink>
                </MenuItem>
                <MenuItem toggle={toggle}>
                  <NavLink href="#">Proses</NavLink>
                </MenuItem>
                <MenuItem toggle={toggle}>
                  <NavLink href="#">Permohonan</NavLink>
                </MenuItem>
                <MenuItem css={`
                  ${maxSM} {
                    position: absolute;
                    right: 20px;
                    top: -40px;
                  }
                `}
                >
                  {
                    getToken() ? (
                      <Link
                        to={`/${getRole() || "login"}`}
                        css={`
                        ${maxSM} {
                          position: relative;
                          top: -5px;
                        }
                      `}
                      >
                        <Avatar style={{ backgroundColor: "#87d068", top: "-5px" }} icon="user" />
                      </Link>
                    ) : (
                      <Link to="/login">
                        <a style={{
                          padding: 10,
                          border: "1px solid",
                        }}
                        >
                          Login
                        </a>
                      </Link>
                    )
                  }
                </MenuItem>
              </Flex>
            </div>
          </Flex>
        </Container>
      </Nav>
      <Banner bg={bg} style={{ position: "relative" }}>
        <Container style={{ maxWidth: 992 }}>
          <Row type="flex" align="middle" style={{ height: 500 }}>
            <Col xs={24} sm={12}>
              <div>
                <BannerHero />
              </div>
            </Col>
            <Col xs={24} sm={12} style={{ alignSelf: "flex-end", height: "100%" }}>
              <DocumentStatus style={{
                position: "absolute",
                bottom: -60,
                right: 0,
              }}
              />
            </Col>
          </Row>
        </Container>
      </Banner>
      <ProcessStyled>
        <Container style={{ maxWidth: 992 }}>
          <div style={{ textAlign: "center", marginBottom: 80 }}>
            <ProcessTitle>Proses Pengajuan</ProcessTitle>
          </div>
          <Process />
        </Container>
      </ProcessStyled>
      <Footer>
        <Container style={{ maxWidth: 992 }}>
          <Row type="flex" gutter={20}>
            <Col xs={24} sm={10}>
              <div>
                <Logo firstColor="#fff" />
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab, accusantium!</p>
              </div>
            </Col>
            <Col xs={24} sm={7}>
              <div>
                <h4>Lokasi</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim, velit.</p>
              </div>
            </Col>
            <Col xs={24} sm={7}>
              <div>
                <h4>Media Sosial</h4>
                <a href="#">
                  <p>Facebook</p>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </Footer>
    </div>
  );
};

export default Landing;
