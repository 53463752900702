/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from "react";
import {
  Button, Form, Input, Modal, DatePicker, Select,
} from "antd";
import styled from "styled-components";
import { Link } from "@reach/router";
import dayjs from "dayjs";
import { Flex, DocPDF } from "components";
import moment from "moment";
import { PDFViewer } from "@react-pdf/renderer";
import {
  globalFetch, ADMIN_REC_LETTER, globalPost, ADMIN_FUNCTIONARY,
} from "utils/api";

const { Option } = Select;

const RecommendationStyled = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  iframe {
    border: none;
  }
`;

const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  right: 0;
  padding: 10px 20px;
  border-bottom: 1px solid #eaeaea;
`;

const Recommendation = ({ id, idLetter, form }) => {
  const [data, setData] = useState(null);
  const [dataFunctionary, setDataFunctionary] = useState(null);
  const [date, setDate] = useState(null);
  const [visible, setVisible] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    if (idLetter !== "generate") {
      globalFetch({ path: `${ADMIN_REC_LETTER}/${idLetter}` })
        .then((_data) => {
          setData(_data.recommendationLetter);
        });
    } else {
      setVisible(true);
    }

    globalFetch({ path: ADMIN_FUNCTIONARY })
      .then((_data) => {
        setDataFunctionary(_data.functionaries);
      });
  }, [refetch, idLetter]);


  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        setLoadingSubmit(true);

        values.recommendation_request_id = id;
        values.date = date;
        if (data) {
          values._method = "patch";
        }

        const newObj = JSON.stringify(values);


        globalPost({ path: `${ADMIN_REC_LETTER}${data ? `/${idLetter}` : ""}`, body: newObj, headers: { "Content-Type": "application/json" } })
          .then((_data) => {
            setLoadingSubmit(false);
            if (data) {
              Modal.success({
                content: "Edit surat berhasil",
              });
            }
            window.location.href = `/admin/permohonan/${id}/rekomendasi/${_data.recommendationLetter.id}`;
            setRefetch(!refetch);
            setVisible(false);
          })
          .catch(() => {
            setLoadingSubmit(false);
          });
      }
    });
  };

  const handleDateFormate = (e) => {
    setDate(dayjs(e).format("YYYY-MM-DD"));
  };

  const handleVisible = () => {
    setVisible(true);
    if (data) {
      setTimeout(() => {
        Object.keys(data).map((key) => {
          if (form.getFieldsValue([key])) {
            if (key === "date") {
              setDate(data[key]);
              form.setFieldsValue({ [key]: moment(data[key]) });
            } else if (key === "functionary_id") {
              const dataPrivate = dataFunctionary && (
                dataFunctionary.filter((item) => item.id === data[key])
              );
              form.setFieldsValue({ [key]: dataPrivate[0].name });
            } else {
              form.setFieldsValue({ [key]: data[key] });
            }
          }
        });
      }, 300);
    }
  };

  const { getFieldDecorator } = form;

  const isGenerate = idLetter === "generate";

  const handlePrint = () => {
    document.getElementsByTagName("iframe")[0].contentWindow.print();
  };

  return (
    <RecommendationStyled>
      <Header>
        <Flex justifyContent="space-between" alignItems="center">
          <div>
            <Link to={`/admin/permohonan/${id}`}>
              <Button size="large" icon="left">Kembali</Button>
            </Link>
          </div>
          <div>
            <Flex gutter={8}>
              <div>
                <Button size="large" type="primary" onClick={handleVisible}>{isGenerate ? "Generate" : "Edit"}</Button>
              </div>
              <div>
                <Button size="large" type="primary" onClick={handlePrint}>Cetak</Button>
              </div>
            </Flex>
          </div>
        </Flex>
      </Header>
      <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <DocPDF data={data} />
      </PDFViewer>
      <Modal
        visible={visible}
        footer={null}
        onOk={handleSubmit}
        showHeader={false}
        destroyOnClose
        onCancel={() => setVisible(false)}
      >
        <div>
          <h4 style={{
            color: "#005083",
            fontSize: 28,
            fontWeight: "bold",
            textAlign: "center",
          }}
          >
            {data ? "Edit" : "Buat"}
            {" "}
Surat
          </h4>
        </div>
        <Form onSubmit={handleSubmit} style={{ margin: "0 auto", maxWidth: 320 }}>
          <Form.Item colon={false} label="Nomor Surat">
            {getFieldDecorator("letter_number", {
              rules: [{ required: true, message: "Please input your NIP!" }],
            })(
              <Input placeholder="NIP" />,
            )}
          </Form.Item>
          <Form.Item colon={false} label="Pejabat">
            {getFieldDecorator("functionary_id", {
              rules: [{ required: true, message: "Please input functionary!" }],
            })(
              <Select>
                {
                  dataFunctionary && dataFunctionary.map((item) => (
                    <Option value={item.id}>{item.name}</Option>
                  ))
                }
              </Select>,
            )}
          </Form.Item>
          <Form.Item colon={false} label="Tanggal">
            {getFieldDecorator("date", {
              rules: [{ required: true, message: "Please input date!" }],
            })(
              <DatePicker format="YYYY-MM-DD" onChange={handleDateFormate} style={{ width: "100%" }} placeholder="Tanggal" />,
            )}
          </Form.Item>
          <div>
            <Button block type="primary" htmlType="submit" loading={loadingSubmit} size="large" style={{ marginTop: 20, textTransform: "uppercase" }}>Simpan</Button>
            <Button block type="link" size="large" onClick={() => setVisible(false)} style={{ marginTop: 20, textTransform: "uppercase", color: "#ff4d4f" }}>Batal</Button>
          </div>
        </Form>
      </Modal>
    </RecommendationStyled>
  );
};

export default Form.create({ name: "recommendation" })(Recommendation);
