/* eslint-disable react/prop-types */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import {
  Card, Flex, Skeleton, DocPDF, DocRequest,
} from "components";
import { DocStyled, ListItem } from "components/utils";
import {
  Button, Modal,
} from "antd";
import {
  PDFDownloadLink, BlobProvider,
} from "@react-pdf/renderer";
import PropTypes from "prop-types";
import { transformData } from "utils";
import {
  globalFetch,
  CITIZEN_REC_REQUEST,
} from "utils/api";

const DownloadRecLetter = ({ data }) => {
  const [mount, setMount] = useState(false);

  useEffect(() => {
    setTimeout(() => setMount(true), 1000);
    return () => setMount(false);
  }, []);

  return (
    mount ? (
      <BlobProvider document={<DocPDF data={data} />}>
        {({ url }) => (
          <a href={url} target="_blank" rel="noopener noreferrer">
            <Button size="large" type="primary">
              Surat Rekomendasi
            </Button>
          </a>
        )}
      </BlobProvider>
    ) : null
  );
};

DownloadRecLetter.propTypes = {
  data: PropTypes.shape.isRequired,
};

const RequestDetail = ({ id }) => {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState(false);
  const [isRecLetter, setIsRecLetter] = useState(false);

  useEffect(() => {
    globalFetch({ path: `${CITIZEN_REC_REQUEST}/${id}` })
      .then((_data) => {
        let newObj = [];
        const recReq = _data.recommendationRequest;
        if (recReq.recommendation_letter) {
          setIsRecLetter(recReq.recommendation_letter);
        }
        setStatus(recReq.recommendation_request_status);

        newObj = transformData(recReq);

        setData(newObj);
      });
  }, []);

  const handleShowAlert = () => {
    Modal.info({
      title: "Info",
      content: "Admin belum membuat surat rekomendasi.",
    });
  };

  return (
    <Card
      title="Detail Permohonan"
      extra={(
        <Flex gutter={8} alignItems="center">
          <div>
            { isRecLetter && <DownloadRecLetter data={isRecLetter} /> }
            { !isRecLetter && <Button size="large" onClick={handleShowAlert} disabled={status && status.name !== "approved"} type="primary">Surat Rekomendasi</Button>}
          </div>
          <div>
            <PDFDownloadLink document={<DocRequest data={data} />} fileName="surat-permohonan.pdf">
              {({ url }) => (
                <a href={url} target="_blank" rel="noopener noreferrer">
                  <Button size="large" type="primary">
                  Cetak Permohonan
                  </Button>
                </a>
              )}
            </PDFDownloadLink>
          </div>
        </Flex>
    )}
      style={{ padding: 24 }}
    >
      <DocStyled>
        <ul>
          {
          data && data.map((item) => {
            if (item.child) {
              return (
                <li>
                  <ul>
                    <span>{item.title}</span>
                    {
                      item.child.map((itemChild) => (
                        <li>
                          <ListItem title={itemChild.title} value={itemChild.content} />
                        </li>
                      ))
                    }
                  </ul>
                </li>
              );
            }
            return (
              <li>
                <ListItem title={item.title} value={item.content} />
              </li>
            );
          })
        }
        </ul>
        {
      !data && (
        <div>
          <Skeleton height={20} marginBottom={20} />
          <Skeleton height={20} marginBottom={20} />
          <Skeleton height={20} marginBottom={20} />
        </div>
      )
    }
      </DocStyled>
    </Card>
  );
};

Request.propTypes = {

};

export default RequestDetail;
