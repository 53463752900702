const theme = {
  light: {
    name: "DEFAULT",
    color: "#333",
    bgInput: "#F3F7FF",
    colorPrimary: "#00B515",
    colorRed: "#F40000",
    colorBlue: "#1BAEC9",
  },
};

export default theme;
