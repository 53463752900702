import cookie from "js-cookie";

export { default as theme } from "./theme";
/**
 * get token from
 */
export const getToken = () => cookie.get("accessToken");

/**
 * set token
 * @param {string} token
 */
export const setToken = (token) => {
  cookie.set("accessToken", token);
};

/**
 * set role
 * @param {string} role
 */
export const setRole = (role) => {
  cookie.set("role", role);
};

/**
 * set cookie
 * @param {string} key
 * @param {string} value
 */
export const setCookie = (key, value) => {
  cookie.set(key, value);
};

/**
 * get cookie from cookie browser
 * @param {string} key
 */
export const getCookie = (key) => cookie.get(key);

/**
 * get user
 */
export const getUser = () => {
  const u = getCookie("user");

  if (u) {
    return JSON.parse(getCookie("user"));
  }
};

/**
 *  remove token
 * */
export const removeCookie = () => {
  cookie.remove("accessToken");
  cookie.remove("roles");
  cookie.remove("user");
};

/**
 * get role from cookie
 */
export const getRole = () => {
  const roles = getCookie("roles");
  const user = getUser();
  if (roles && user) {
    const rolesParsed = JSON.parse(roles);
    // match role
    const roleName = rolesParsed.filter((item) => item.id === user.role_id);
    return roleName[0].name;
  }
};

/**
 * media queries
 */

export const minSM = "@media (min-width: 577.98px)";
export const minMD = "@media (min-width: 769.98px)";
export const minLG = "@media (min-width: 993.98px)";
export const minXL = "@media (min-width: 1201.98px)";

export const maxSM = "@media (max-width: 575.98px)";
export const maxMD = "@media (max-width: 767.98px)";
export const maxLG = "@media (max-width: 991.98px)";
export const maxXL = "@media (max-width: 1199.98px)";

export const media = ({ type = "min", width = 993.98 }) => `@media (${type}-width: ${width}px)`;

export const redirect = (code, path = "/login", cb) => {
  if (code === 401) {
    removeCookie();
    window.location.href = path;
  }
};

const columns = [
  {
    title: "Nama",
    key: "requestor_name",
  },
  {
    title: "Institusi",
    key: "institution_name",
  },
  {
    title: "Jabatan",
    key: "requestor_position",
  },
  {
    title: "Jenis Peruntukan",
    key: "requested_activity",
  },
  {
    title: "Nomor Sertifikat",
    key: "land_certificate_number",
  },
  {
    title: "Tinggi Bangunan",
    key: "building_height",
  },
  {
    title: "Status Surat Rekomendasi",
    key: "recommendation_request_status",
    child: "caption",
  },
  {
    title: "Luas Bangunan",
    key: "request_floor_areas",
    child: {
      title: "Lantai",
      key: "area",
    },
  },
];

export const transformData = (recReq) => {
  const newObj = [];

  Object.keys(recReq).map((key) => {
    columns.map((item) => {
      if (item.key === key) {
        if (item.child) {
          if (Array.isArray(recReq[key])) {
            const items = [];
            recReq[key].map((itemChild, i) => {
              Object.keys(itemChild).map((keyChild) => {
                if (keyChild === item.child.key) {
                  let itemPrivate = {};
                  itemPrivate = {
                    ...itemPrivate,
                    title: `${item.child.title} ${i + 1}`,
                    key: keyChild,
                    content: itemChild[keyChild],
                  };
                  items.push(itemPrivate);
                }
              });
            });
            newObj.push({
              title: item.title,
              key: item.key,
              child: items,
            });
          } else {
            newObj.push({
              title: item.title,
              key: item.key,
              content: recReq[key][item.child],
            });
          }
        } else {
          newObj.push({
            title: item.title,
            key: item.key,
            content: recReq[key],
          });
        }
      }
    });
  });

  return newObj;
};

