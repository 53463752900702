/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  forwardRef, useEffect, useRef, useState,
} from "react";
import ReactDOM from "react-dom";
import { Button, Divider, Icon } from "antd";
import { Link, navigate } from "@reach/router";

import {
  Map, View, Geolocation,
} from "ol";

import { Draw, Modify } from "ol/interaction";
import { singleClick } from "ol/events/condition";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { OSM, Vector as VectorSource } from "ol/source";
import { MultiPoint, LineString } from "ol/geom";
import GeoJSON from "ol/format/GeoJSON";
import Overlay from "ol/Overlay";

import * as olExtent from "ol/extent";

import Feature from "ol/Feature";
import Select from "ol/interaction/Select";
import { transform } from "ol/proj";
import {
  Circle as CircleStyle, Fill, Stroke, Style,
} from "ol/style";
import styled from "styled-components";
import Flex from "../flex";

const MapsStyled = styled.div`
  &&& {
    .ol-overlaycontainer {
      z-index: initial !important;
    }
  }
`;

const PopoverStyled = styled.div`
  padding: 20px;
  font-size: 14px;
  line-height: 1.5;
  position: absolute;
  top: -20px;
  min-width: 280px;
  font-size: 12px;
  left: 50px;
  z-index: 999999;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  cursor: auto;
  &:after {
    position: absolute;
    left: -60px;
    content: " ";
    top: 0;
    width: 30px;
    height: 30px;
    background: #2196F3;
    border-radius: 50%;
    border: 5px solid #2cd2e7;
  }
`;

const ListItem = styled.div`
  & > div {
    padding: 2px 0;
    & > div {
      &:first-of-type {
        width: 120px;
        flex: none;
      }
      &:last-of-type {
        color: #333;
      }
    }
  }
`;

const Popover = forwardRef(({
  // eslint-disable-next-line react/prop-types
  handleExpand, handleClose, data, isRequest, ...props
}, ref) => (
  <PopoverStyled ref={ref} {...props}>
    <div>
      <h4 style={{ fontSize: 16, marginBottom: 20 }}>Detail Syarat Permohonan</h4>
      <Icon
        type="close"
        onClick={handleClose}
        style={{
          position: "absolute",
          right: 10,
          cursor: "pointer",
          top: 10,
        }}
      />
      <ListItem>
        <Flex gutter={8}>
          <div>
            <span>Peruntukan</span>
          </div>
          <div>
            <span>Fasilitas Perdagangan</span>
          </div>
        </Flex>
      </ListItem>
      <ListItem>
        <Flex gutter={8}>
          <div>
            <span>KLB Maskimal</span>
          </div>
          <div>
            <span>{data.klb_max || "-"}</span>
          </div>
        </Flex>
      </ListItem>
      <ListItem>
        <Flex gutter={8}>
          <div>
            <span>KBD Maskimal</span>
          </div>
          <div>
            <span>{data.kbd_max}</span>
          </div>
        </Flex>
      </ListItem>
      <ListItem>
        <Flex gutter={8}>
          <div>
            <span>KDH Minimum</span>
          </div>
          <div>
            <span>{data.kdh_min}</span>
          </div>
        </Flex>
      </ListItem>
      <ListItem>
        <Flex gutter={8}>
          <div>
            <span>Maks Jumlah Lantai</span>
          </div>
          <div>
            <span>
              {data.max_floor}
              {" "}
lantai
            </span>
          </div>
        </Flex>
      </ListItem>
      <ListItem>
        <Flex gutter={8}>
          <div>
            <span>Keterangan</span>
          </div>
          <div>
            <span>{data.information}</span>
          </div>
        </Flex>
      </ListItem>
      {
        isRequest && (
          <div style={{ marginTop: 20 }}>
            <Button onClick={handleExpand} style={{ textTransform: "uppercase" }} block type="primary">Isi Data</Button>
          </div>
        )
      }
    </div>
  </PopoverStyled>
));

const Information = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
  background: #fff;
  padding: 20px;
`;

const raster = new TileLayer({
  source: new OSM(),
});

const styles = [
  new Style({
    stroke: new Stroke({
      color: "blue",
      width: 3,
    }),
    fill: new Fill({
      color: "rgba(0, 0, 255, 0.1)",
    }),
  }),
  new Style({
    image: new CircleStyle({
      radius: 2,
      fill: new Fill({
        color: "orange",
      }),
    }),
    geometry(feature) {
      // return the coordinates of the first ring of the polygon
      const coordinates = feature.getGeometry().getType();
      return new MultiPoint(coordinates);
    },
  }),
];

/**
 *
 * @param isEdit properti yg diget dari url untuk menntukan maps berada di
 * halaman create area atau edit
 * @param centerArea prperti yang dilewatkan dari component permohonan di citizen dan
 * dari admin untuk menentukan center view maps saat diload
 * @param isSurveyor properti untuk mengecek bahwa maps berada dihalaman surveyor
 * @param isRequest sama dengan isSurveyor tapi ini untuk hamalan permohaoan di citizen
 * @param type properti yang nanti nya menggantikan isSurveyor/isRequest, hanya untuk
 * menentukan bahwa maps diload id role mana, untuk sekarang type hanya digunakan di admin
 * @param dataFeatures properti data feature maps ygn dilewatkan dari component admin dan citizen
 * @param dataSurveyor properti data surveyor yang dilewatkan dari compnent survyor
 */
const Maps = ({
  drawable, handleDrawCancel, isEdit, centerArea,
  handleDrawable, handleProperties, isSurveyor, type,
  modalVisible, handleModalVisible, handleCoordinates, dataFeatures,
  style, isRequest, handleExpand, center, dataSurveyor,
}) => {
  const popoverRef = useRef(null);
  const mapsRef = useRef(null);
  const [drawstarted, setDrawStarted] = useState(false);
  const [loadingSurvey] = useState(false);
  const [maps, setMaps] = useState(null);
  const [element, setElement] = useState(null);
  const [popup, setPopup] = useState(null);
  const [feature, setFeature] = useState(null);
  const [vector, setVector] = useState(null);
  const [isModified, setIsModified] = useState(false);
  // const [snap, setSnap] = useState(null);

  /**
   * Instansi VectorSurce
   */
  const source = new VectorSource({
    features: [],
    format: new GeoJSON(),
  });

  /**
   * Instansi Vector Layer
   */
  const vectorLayer = new VectorLayer({
    source,
    style: styles,
  });

  /**
   * Intansi View
   */
  const view = new View({
    center: transform([119.6817907, -3.6364113], "EPSG:4326", "EPSG:3857"),
    zoom: 10,
  });

  /**
   * Intansi Draw
   */
  const draw = new Draw({
    source,
    type: "Polygon",
  });

  /**
   * Intansi Select
   */
  const selectInteraction = new Select({
    condition: singleClick,
  });

  /**
   * Intansi Modify
   */
  const modify = new Modify({
    features: selectInteraction.getFeatures(),
  });

  // const pickRandomProperty = () => {
  //   const prefix = ["bottom", "center", "top"];
  //   const randPrefix = prefix[Math.floor(Math.random() * prefix.length)];
  //   const suffix = ["left", "center", "right"];
  //   const randSuffix = suffix[Math.floor(Math.random() * suffix.length)];
  //   return `${randPrefix}-${randSuffix}`;
  // };

  /**
   * Bagian initialization
   */
  useEffect(() => {
    /**
     * Instance Maps di set ke state maps
     */
    setMaps(new Map({
      target: mapsRef.current,
      layers: [
        raster, vectorLayer,
      ],
      controls: [],
      view,
    }));

    /**
     * Vektor layer di set ke state vector
     */
    setVector(vectorLayer);

    const el = document.getElementById("popup");

    setElement(el);

    setPopup(
      new Overlay({
        element: el,
      }),
    );
  }, []);

  /**
   * transform feature to geojson
   */
  const transformToGeojson = (e) => {
    const geo = e.getGeometry();
    // const geoString = writer.writeFeatures(vector.getFeatures());
    setFeature(e);
    // const feauture = new Feature({
    //   geometry: geo,
    // });

    const obj = (new GeoJSON()).writeGeometryObject(geo);
    const newObj = {};
    newObj.coordinates = obj.coordinates[0].map((item, i) => ({
      order: i,
      latitude: item[0],
      longitude: item[1],
    }));
    newObj.center_coordinate = {
      longitude: olExtent.getCenter(geo.getExtent())[0],
      latitude: olExtent.getCenter(geo.getExtent())[1],
    };
    handleCoordinates(newObj);
    handleModalVisible(true);
  };

  // var flickrSource = new ol.source.Vector()
  // var transform = ol.proj.getTransform('EPSG:4326', 'EPSG:3857');
  // data.items.forEach(function(item) {
  //   var feature = new ol.Feature(item);
  //   feature.set('url', item.media.m);
  //   var coordinate = transform([parseFloat(item.longitude), parseFloat(item.latitude)]);
  //   var geometry = new ol.geom.Point(coordinate);
  //    feature.setGeometry(geometry);
  //   flickrSource.addFeature(feature);
  // });

  useEffect(() => {
    /**
     * Bagian restore coordinate dari api ke maps yang ditransform ke geolocation
     */
    if (dataFeatures && maps) {
      maps.addOverlay(popup);

      const geojsonObject = {
        type: "FeatureCollection",
        features: dataFeatures,
      };

      const features = (new GeoJSON()).readFeatures(geojsonObject);


      /**
       * Intansi baru vector source
       */
      const getVectorSource = new VectorSource({
        features,
        format: new GeoJSON(),
      });

      vector.setSource(
        getVectorSource,
      );

      // const modify = new Modify({ features: vectorSource.getFeaturesCollection() });
      // maps.addInteraction(modify);

      maps.getInteractions().extend([selectInteraction, modify]);
      const selectedFeatures = selectInteraction.getFeatures();

      // maps.getView().on('change:resolution', (event) => {
      //   // maps.getInteractions().extend([selectInteraction, modify]);
      //   getVectorSource.refresh()
      // });

      /**
       * Edit feature/area
       */
      selectedFeatures.on("add", (evt) => {
        const ftr = evt.element;
        ftr.on("change", () => {
          const obj = (new GeoJSON()).writeGeometryObject(ftr.getGeometry());
          const newObj = {};
          newObj.coordinates = obj.coordinates[0].map((item, i) => ({
            order: i,
            latitude: item[0],
            longitude: item[1],
          }));
          newObj.center_coordinate = {
            longitude: olExtent.getCenter(ftr.getGeometry().getExtent())[0],
            latitude: olExtent.getCenter(ftr.getGeometry().getExtent())[1],
          };
          handleCoordinates(newObj);
          setIsModified(true);
        });
      });
    }
  }, [dataFeatures, maps]);

  useEffect(() => {
    if (isEdit && modalVisible) {
      setIsModified(false);
    }
  }, [modalVisible]);

  const handleRemoveDraw = () => {
    maps.getInteractions().getArray().forEach((interaction) => {
      if (interaction instanceof Draw) {
        maps.removeInteraction(interaction);
        setDrawStarted(false);
      }
    });
  };

  const handleCancel = () => {
    handleDrawable(false);
    handleDrawCancel(true);
    setDrawStarted(false);
  };

  const handleModified = () => {
    handleModalVisible(true);
  };

  useEffect(() => {
    if (maps) {
      /**
       * bagian yang menghandle popover ketika area diklik
       * bagian ini terhandle di halaman admin dan permohonan di citizen
       */
      maps.on("click", (e) => {
        const coordinatePrivate = e.coordinate;
        const featureArea = maps.forEachFeatureAtPixel(e.pixel, (ftr) => ftr);

        /**
         * Mengecek apakah data feature area tersedia
         */
        if (featureArea) {
          // const geometry = featureArea.getGeometry().getGeometries()[0];
          const centerCoordinate = {
            longitude: coordinatePrivate[0],
            latitude: coordinatePrivate[1],
          };

          const handleClose = () => {
            ReactDOM.render(null, element);
          };

          const popoverEl = (
            <Popover
              ref={popoverRef}
              isRequest={isRequest}
              handleClose={handleClose}
              data={featureArea.getProperties()}
              handleExpand={() => handleExpand(true)}
            />
          );

          handleProperties(featureArea.getProperties());
          if (center) {
            center.setCoordinate(centerCoordinate);
          }
          ReactDOM.render(popoverEl, element);
          // popup.setPosition(pickRandomProperty());
          popup.setPosition(coordinatePrivate);
        } else if (isRequest) {
          /**
           * isRequest merupakan properti yg dilewatkan dari halaman citizen
           */
          handleExpand(false);
        }
      });

      const handleSetCenterArea = (_center) => {
        const centerPrivate = _center.properties.center_coordinate;
        // jika data coordinate center tersedia
        if (centerPrivate) {
          maps.getView().setCenter([centerPrivate.longitude, centerPrivate.latitude]);
          maps.getView().setZoom(17);
        }
      };

      /**
       * handle center view
       * tereksekusi jika component maps berada di path admin atau halamana permohonan
       */
      if (type === "admin" || isRequest) {
        if (centerArea) {
          handleSetCenterArea(isRequest ? centerArea : centerArea[0]);
        }
      }

      if (type === "admin" && !isEdit) {
        maps.addInteraction(draw);
        if (drawable) {
          draw.on("drawend", (e) => {
            setDrawStarted(false);

            // transform feature to geojson
            transformToGeojson(e.feature);
          });

          // selectClick.on("select", (e) => {
          //   console.log(e.target.getFeatures());
          // });
        } else {
          handleRemoveDraw();
        }
        draw.on("drawstart", () => {
          setDrawStarted(true);
          handleDrawable(true);
        });
      }
    }
  }, [drawable, maps, centerArea]);

  useEffect(() => {
    /**
     * Bagian yang menhandle geolocation area dan pemohon
     * bagian ini terhandle di surveyor
     * isSurveyor merupakan properti yang dilewatkan dari component surveyor
     */
    if (isSurveyor) {
      if (maps) {
        const trackFeature = new Feature({
          geometry: new LineString([]),
          style: new Style({
            image: new CircleStyle({
              radius: 4,
              fill: new Fill({
                color: "#3399CC",
              }),
              stroke: new Stroke({
                color: "#fff",
                width: 1,
              }),
            }),
          }),
        });
        vector.setSource(new VectorSource({ features: [trackFeature] }));

        const geolocation = new Geolocation({
          tracking: true,
          projection: view.getProjection(),
        });
        const marker = new Overlay({
          element: document.getElementById("location"),
          positioning: "center-center",
        });
        const myLocMarker = new Overlay({
          element: document.getElementById("myLocation"),
          positioning: "center-center",
        });
        maps.addOverlay(marker);
        maps.addOverlay(myLocMarker);
        // const centerCoordinate = {
        //   longitude: dataSurveyor.longitude,
        //   latitude: dataSurveyor.latitude,
        // };
        if (dataSurveyor) {
          maps.getView().setCenter([Number(dataSurveyor.longitude), Number(dataSurveyor.latitude)]);
          maps.getView().setZoom(17);
          marker.setPosition([Number(dataSurveyor.longitude), Number(dataSurveyor.latitude)]);
        }

        geolocation.on("change", () => {
          const coordinatePrivate = geolocation.getPosition();
          view.setCenter(coordinatePrivate);
          myLocMarker.setPosition(coordinatePrivate);
          trackFeature.getGeometry().appendCoordinate(coordinatePrivate);
        });
      }
    }
  }, [isSurveyor, maps, dataSurveyor]);

  return (
    <MapsStyled ref={mapsRef} style={{ height: "100vh", position: "relative", ...style }}>
      <div id="popup" />
      {
        isSurveyor && (
          <>
            <div id="location">
              <Icon type="environment" theme="filled" style={{ fontSize: 50, color: "#2196F3" }} />
            </div>
            <div id="myLocation">
              <Icon type="environment" theme="filled" style={{ fontSize: 50, color: "#4caf50" }} />
            </div>
          </>
        )
      }
      {
        !isRequest && !isSurveyor && (
          <Information>
            <Flex gutter={8} alignItems="center">
              {
                drawstarted ? (
                  <>
                    <div>
                      <span>Klik titik selanjutnya untuk membuat garis.</span>
                    </div>
                    {
                      drawable && (
                        [
                          <Divider type="vertical" />,
                          <div>
                            <Button type="link" onClick={handleCancel}>Cancel</Button>
                          </div>,
                        ]
                      )
                    }
                  </>
                ) : (
                  <>
                    <div>
                      <span>Klik untuk membuat titik awal area peruntukan.</span>
                    </div>
                    {
                      isModified && (
                        [
                          <Divider type="vertical" />,
                          <div>
                            <Button type="link" onClick={handleModified}>Selesai</Button>
                          </div>,
                        ]
                      )
                    }
                  </>
                )
              }
            </Flex>
          </Information>
        )
      }
      {
        isSurveyor && (
          <Information style={{
            left: 0, right: 0, bottom: 0, background: "none",
          }}
          >
            <Flex gutter={8} alignItems="center">
              <div style={{ flexBasis: "30%" }}>
                <Button onClick={() => navigate(`/surveyor/${dataSurveyor && dataSurveyor.id}`)} size="large">Kembali</Button>
              </div>
              <div style={{ flexBasis: "70%" }}>
                <Link to={`/surveyor/${dataSurveyor && dataSurveyor.id}/evaluasi`}>
                  <Button block type="primary" loading={loadingSurvey} size="large">Isi Form Evaluasi</Button>
                </Link>
              </div>
            </Flex>
          </Information>
        )
      }
    </MapsStyled>
  );
};

export default Maps;
