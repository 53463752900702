import React, { useState, useEffect } from "react";
import {
  Card, Flex, Skeleton, SEO,
} from "components";
import {
  Button, Input, Modal, Table, Select, Form,
} from "antd";
import doc from "images/document.png";
import { tableNoBorder } from "components/utils";
import PropTypes from "prop-types";
import {
  ADMIN_ROLES, globalFetch, globalPost,
  ADMIN_USER,
} from "utils/api";


const { Option } = Select;

const Citizen = ({ form }) => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(null);
  const [roles, setRoles] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    setLoading(true);
    globalFetch({ path: ADMIN_USER })
      .then((_data) => {
        setLoading(false);
        setData(_data.users);
      });
  }, [refetch]);

  useEffect(() => {
    globalFetch({ path: ADMIN_ROLES })
      .then((_data) => {
        setRoles(_data.roles);
      });
  }, []);

  const columns = [
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        const formData = new FormData();
        setLoadingSubmit(true);
        formData.append("name", values.name);
        formData.append("password", values.password);
        formData.append("email", values.email);
        formData.append("role_id", values.role_id);
        globalPost({ path: ADMIN_USER, body: formData })
          .then(() => {
            setLoadingSubmit(false);
            setVisible(false);
            setRefetch(!refetch);
          })
          .catch(() => {
            setLoadingSubmit(false);
          });
      }
    });
  };

  const { getFieldDecorator } = form;

  return (
    <Card
      title="Masyarakat"
      extra={(
        <Button block type="primary" onClick={() => setVisible(true)} size="large" style={{ textTransform: "uppercase" }}>Tambah Baru</Button>
      )}
      style={{ padding: 24 }}
    >
      <SEO title="Pengguna" />
      {
        data && (
          <div>
            <Table css={tableNoBorder} loading={loading} dataSource={data} columns={columns} />
          </div>
        )
      }
      {
        loading && (
          <div>
            <Skeleton height={20} marginBottom={20} />
            <Skeleton height={20} marginBottom={20} />
            <Skeleton height={20} marginBottom={20} />
          </div>
        )
      }
      {
        data && !data.length ? (
          <Flex justifyContent="center" style={{ height: "50vh" }} alignItems="center">
            <div style={{ textAlign: "center" }}>
              <img src={doc} alt="" />
              <p style={{ marginTop: 20 }}>
                Belum ada data area untuk peruntukan “
                {data && data.name}
                ”
              </p>
              <Button onClick={() => setVisible(true)} type="primary" size="large" style={{ marginTop: 20, textTransform: "uppercase" }}>Tambah Area</Button>
            </div>
          </Flex>
        ) : null
      }
      <Modal
        title="Tambah Surveyor"
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item colon={false} label="Nama">
            {getFieldDecorator("name", {
              rules: [{ required: true, message: "Please input your nama!" }],
            })(
              <Input placeholder="Nama Peruntukan" />,
            )}
          </Form.Item>
          <Form.Item colon={false} label="Email">
            {getFieldDecorator("email", {
              rules: [{ required: true, message: "Please input your nama!" }],
            })(
              <Input type="email" placeholder="Email" />,
            )}
          </Form.Item>
          <Form.Item colon={false} label="Password">
            {getFieldDecorator("password", {
              rules: [{ required: true, message: "Please input your password!" }],
            })(
              <Input.Password placeholder="Password" />,
            )}
          </Form.Item>
          <Form.Item colon={false} label="Hak Akses">
            {getFieldDecorator("role_id", {
              rules: [{ required: true, message: "Please select your role!" }],
            })(
              <Select placeholder="Please select a role">
                {
                  roles && roles.map((item) => (
                    <Option value={item.id}>{item.caption}</Option>
                  ))
                }
              </Select>,
            )}
          </Form.Item>
          <div>
            <Button block type="primary" size="large" htmlType="submit" loading={loadingSubmit} style={{ marginTop: 20, textTransform: "uppercase" }}>Simpan</Button>
          </div>
        </Form>
      </Modal>
    </Card>
  );
};

Citizen.propTypes = {
  form: PropTypes.shape.isRequired,
};

export default Form.create({ name: "citizen" })(Citizen);
