import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Link } from "@reach/router";
import { CITIZEN_REC_REQUEST, globalFetch } from "utils/api";
import {
  ButtonStatus,
  UserContentWrap, Title, UserContentChild,
} from "../styled";


const UserRequest = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    globalFetch({ path: CITIZEN_REC_REQUEST })
      .then((_data) => {
        setLoading(false);
        setData(_data.recommendationRequests);
      });
  }, []);

  const columns = [
    {
      title: "Status",
      dataIndex: "recommendation_request_status",
      key: "recommendation_request_status",
      render: (val) => (
        <ButtonStatus>{val && (val.caption || "-")}</ButtonStatus>
      ),
    },
    {
      title: "No Pendaftaran",
      dataIndex: "land_certificate_number",
      key: "land_certificate_number",
      render: (text, values) => (
        <span style={{ color: "#165EE5" }}>
          <Link to={`/citizen/permohonan/${values.id}`}>
            {text}
          </Link>
        </span>
      ),
    },
    {
      title: "Perusahaan",
      dataIndex: "institution_name",
      key: "institution_name",
    },
    {
      title: "No. Telephone",
      dataIndex: "phone",
      key: "phone",
      render: (val) => (
        val || "-"
      ),
    },
    {
      title: "Usulan Permohonan",
      dataIndex: "requested_activity",
      key: "requested_activity",
    },
  ];

  return (
    <div>
      <UserContentWrap>
        <Title>Riwayat Permohonan</Title>
        <UserContentChild>
          <Table bordered loading={loading} dataSource={data} columns={columns} />
        </UserContentChild>
      </UserContentWrap>
    </div>
  );
};

export default UserRequest;
