import styled, { keyframes } from "styled-components";

const anim = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
`;

const Skeleton = styled.div`
  background: #bccad38f;
  height: 8px;
  width: 100%;
  border-radius: 3px;
  animation: ${anim} 2s linear;
  animation-iteration-count: infinite;
  ${(props) => ({ ...props })}
`;

export default Skeleton;
