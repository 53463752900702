import React from "react";
import { Avatar as AvatarAntd } from "antd";
import styled from "styled-components";
import PropTypes from "prop-types";
import { maxSM } from "utils";

const Name = styled.span`
  ${maxSM} {
    display: none;
  }
`;

const Avatar = ({ name }) => (
  <div>
    {
      name && (
        <Name>{name}</Name>
      )
    }
    <AvatarAntd style={{ backgroundColor: "#87d068", marginLeft: 10 }} icon="user" />
  </div>
);

Avatar.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Avatar;
