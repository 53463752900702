import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

const SEO = ({ title }) => (
  <Helmet
    titleTemplate="%s - Advice Planning"
    defaultTitle="Advice Planning"
  >
    <title itemProp="name" lang="id">{title}</title>
  </Helmet>
);

SEO.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SEO;
