/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useContext } from "react";
import {
  Button, Select, Form, Input, Icon, Modal, Row, Col,
} from "antd";
import {
  globalFetch, CITIZEN_ALLOTMENT, globalPost, CITIZEN_REC_REQUEST,
} from "utils/api";
import { Link } from "@reach/router";
import { RequestContext } from "contexts/request-context";
// import vote from "images/positive-vote.png";
import { MakeRequestStyled, Selection, FormAddtional } from "./styled";
import Flex from "../flex";

const { Option } = Select;

const MakeRequest = ({ form, ...props }) => {
  const [id, setId] = useState(1);
  const [data, setData] = useState(null);
  const [dataArea, setDataArea] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const {
    expand, setIdArea, coordinate, properties,
  } = useContext(RequestContext);
  const [allotmentType, setAllotmentType] = useState(null);

  useEffect(() => {
    globalFetch({ path: CITIZEN_ALLOTMENT })
      .then((_data) => {
        setData(_data.allotments);
      });

    form.setFieldsValue({
      keys: [0],
    });
  }, []);

  useEffect(() => {
    if (allotmentType) {
      globalFetch({ path: `${CITIZEN_ALLOTMENT}/${allotmentType}` })
        .then((_data) => {
          setDataArea(_data.allotment);
        });
    }
  }, [allotmentType]);

  const remove = (key) => {
    const keys = form.getFieldValue("keys");

    if (keys.length === 1) {
      return;
    }

    form.setFieldsValue({
      keys: keys.filter((k) => k !== key),
    });
  };

  const add = () => {
    setId(id + 1);
    const keys = form.getFieldValue("keys");
    const nextKeys = keys.concat(id);

    form.setFieldsValue({
      keys: nextKeys,
    });
  };

  const onSearch = (val) => {
    console.log("search:", val);
  };

  const onChange = (val) => {
    setAllotmentType(val);
    setIdArea(val);
  };

  const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        setLoading(true);
        const tempFloors = values.request_floor_areas;
        values.allotment_id = allotmentType;
        values.longitude = coordinate.longitude;
        values.latitude = coordinate.latitude;
        values.request_floor_areas = tempFloors.map((item, i) => ({
          floor_number: i + 1,
          area: item,
        }));

        delete values.keys;

        globalPost({ path: CITIZEN_REC_REQUEST, body: JSON.stringify(values), headers: { "Content-Type": "application/json" } })
          .then(() => {
            setSubmitSuccess(true);
          })
          .then(() => {
            setLoading(false);
          });
      }
    });
  };

  // const checkValue = (fields, value) => {
  //   console.log(value, fields);
  // };

  const { getFieldDecorator, getFieldValue } = form;

  getFieldDecorator("keys", { initialValue: [] });

  const keys = getFieldValue("keys");

  const formItems = keys.map((k, i) => (
    <Form.Item
      colon={false}
      label={i === 0 ? "Luas Bangunan" : ""}
      key={k}
      labelAlign="left"
      labelCol={{ span: 10 }}
      wrapperCol={i === 0 ? { span: 14 } : { span: 14, offset: 10 }}
    >
      {
        getFieldDecorator(`request_floor_areas[${k}]`, {
          validateTrigger: ["onChange", "onBlur"],
          rules: [
            {
              required: true,
            },
          ],
        })(
          <Input
            placeholder="00"
            prefix={(
              <span>
Lantai
                {i + 1}
              </span>
)}
            style={{ paddingLeft: 80 }}
            suffix={(
              <div>
                <span style={{ verticalAlign: "middle" }}>
                  m
                  <sup>2</sup>
                </span>
                {k > 0 ? (
                  <Icon
                    className="dynamic-delete-button"
                    type="minus-circle-o"
                    onClick={() => remove(k)}
                    style={{ verticalAlign: "middle", marginLeft: 20, color: "#E91E63" }}
                  />
                ) : null}
              </div>
            )}
          />,
        )
      }
    </Form.Item>
  ));

  // const imageVote = () => <img style={{ width: "100%" }} src={vote} />;

  return (
    <MakeRequestStyled expand={expand} {...props}>
      <Selection>
        <div>
          <Flex alignItems="center" justifyContent="space-between">
            <div>
              <h3>Buat Permohonan</h3>
            </div>
            <div>
              <Button style={{ color: "#e91e63" }} type="link" onClick={() => window.history.back()}>Batal</Button>
            </div>
          </Flex>
        </div>
        <div style={{ marginTop: 20 }}>
          <Form.Item colon={false} label="Jenis Peruntukan">
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Pilih Jenis Peruntukan"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {
                data && data.map((item) => (
                  <Option value={item.id}>{item.name}</Option>
                ))
              }
            </Select>
          </Form.Item>
        </div>
      </Selection>
      {
        expand && (
          <FormAddtional>
            <div>
              <h4>Form Perizinan</h4>
            </div>
            <Form onSubmit={handleSubmit} layout="horizontal">
              <Form.Item colon={false} label="Kordinat" labelAlign="left" {...formItemLayout}>
                <Input disabled value={`${coordinate.longitude}, ${coordinate.latitude}`} />
              </Form.Item>
              <Form.Item colon={false} label="Nama Pemohon" labelAlign="left" {...formItemLayout}>
                {getFieldDecorator("requestor_name", {
                  rules: [{ required: true, message: "Masukkan nama pemohon" }],
                })(
                  <Input />,
                )}
              </Form.Item>
              <Form.Item colon={false} label="Institusi Pemohon" labelAlign="left" {...formItemLayout}>
                {getFieldDecorator("institution_name", {
                  rules: [{ required: true, message: "Masukkan institusi pemohon" }],
                })(
                  <Input />,
                )}
              </Form.Item>
              <Form.Item colon={false} label="Jabatan Pemohon" labelAlign="left" {...formItemLayout}>
                {getFieldDecorator("requestor_position", {
                  rules: [{ required: true, message: "Masukkan jabatan pemohon" }],
                })(
                  <Input />,
                )}
              </Form.Item>
              <Form.Item colon={false} label="Alamat Pemohon" labelAlign="left" {...formItemLayout}>
                {getFieldDecorator("address", {
                  rules: [{ required: true, message: "Masukkan alamat pemohon" }],
                })(
                  <Input />,
                )}
              </Form.Item>
              <Form.Item colon={false} label="Usulan Kegiatan" labelAlign="left" {...formItemLayout}>
                {getFieldDecorator("requested_activity", {
                  rules: [{ required: true, message: "Masukkan usulan pemohon" }],
                })(
                  <Input />,
                )}
              </Form.Item>
              <Form.Item colon={false} label="Luas Tanah" labelAlign="left" {...formItemLayout}>
                {getFieldDecorator("land_area", {
                  rules: [{ required: true, message: "Masukkan luas tanah" }],
                })(
                  <Input
                    placeholder="00"
                    suffix={(
                      <span>
m
                        <sup>2</sup>
                      </span>
                    )}
                  />,
                )}
              </Form.Item>
              {formItems}
              {
                form.getFieldValue("keys").length < properties.max_floor && (
                  <Form.Item colon={false} label="" labelAlign="left" wrapperCol={{ span: 14, offset: 10 }}>
                    <Button size="large" icon="plus" onClick={add}>Lantai</Button>
                  </Form.Item>
                )
              }
              <Form.Item colon={false} label="Tinggi Bangunan" labelAlign="left" {...formItemLayout}>
                {getFieldDecorator("building_height", {
                  rules: [{ required: true, message: "Masukkan tinggi bangunan" }],
                })(
                  <Input placeholder="00" suffix="m" />,
                )}
              </Form.Item>
              <Form.Item colon={false} label="No. Sertifikat Tanah" labelAlign="left" {...formItemLayout}>
                {getFieldDecorator("land_certificate_number", {
                  rules: [{ required: true, message: "Masukkan nomor sertifikat" }],
                })(
                  <Input placeholder="00" />,
                )}
              </Form.Item>
              <div style={{ marginTop: 60 }}>
                <Flex gutter={8}>
                  <div style={{ width: "100%" }}>
                    <Button block size="large">Batal</Button>
                  </div>
                  <div style={{ width: "100%" }}>
                    <Button block htmlType="submit" loading={loading} size="large" type="primary">Kirim</Button>
                  </div>
                </Flex>
              </div>
            </Form>
          </FormAddtional>
        )
      }
      <Modal
        visible={submitSuccess}
        closable={false}
        footer={null}
        style={{ minWidth: 660 }}
      >
        <Row type="flex" gutter={60} align="middle">
          <Col sm={8}>
            <div>
              <svg css={".a{fill:#00c850;}.b{fill:#20ac58;}.c{fill:#fdd7bd;}.d{fill:#a1cead;}.e{fill:#528d69;}.f{fill:#fac5aa;}.g{fill:none;stroke:#20ac58;}"} width="100%" height="223.407" viewBox="0 0 195.72 223.407">
                <defs />
                <g transform="translate(-1125.833 -256.402)">
                  <g transform="translate(1141.5 285.894)">
                    <path className="a" d="M163.223,130.777A81.362,81.362,0,1,1,81.86,49.414,81.363,81.363,0,0,1,163.223,130.777Zm0,0" transform="translate(0 -32.031)" />
                    <path className="b" d="M208.486,49.414a81.968,81.968,0,0,0-12.646.98,81.371,81.371,0,0,1,.005,160.762,81.365,81.365,0,1,0,12.64-161.742Zm0,0" transform="translate(-126.625 -32.031)" />
                    <path className="c" d="M289.252,92.731,242.792,123.5a15.823,15.823,0,0,1-11.239,2.436,15.875,15.875,0,0,0-11.266,2.429l-6.218,4.121L179.25,81.156l8.543-36.905A38.521,38.521,0,0,0,182.382,14.3,9.21,9.21,0,0,1,196.47,2.6l5.535,5.36a59.15,59.15,0,0,1,14.277,21.887l3,8.08,30.9-20.419a10.538,10.538,0,0,1,14.765,3.217,10.677,10.677,0,0,1-3.31,14.475.116.116,0,0,0,.107.2,10.487,10.487,0,0,1,3.766-.7,10.6,10.6,0,0,1,5.742,1.692,10.54,10.54,0,0,1,4.6,10.891,10.764,10.764,0,0,1-4.418,6.673.227.227,0,0,0-.058.313.009.009,0,0,0,.007.007.224.224,0,0,0,.3.059,10.815,10.815,0,0,1,2.557-.3,11.1,11.1,0,0,1,5.944,1.723,10.363,10.363,0,0,1,3.074,3.016,10.534,10.534,0,0,1-2.971,14.6c-.12.081-.239.162-.359.239a.164.164,0,0,0-.054.2.087.087,0,0,0,.038.038.17.17,0,0,0,.209-.007,10.316,10.316,0,0,1,8.94,1.15,10.736,10.736,0,0,1,3.148,3.121A10.541,10.541,0,0,1,289.252,92.731Zm0,0" transform="translate(-115.871 0)" />
                    <path className="d" d="M100.052,278.793l-19.6,12.98-28.3,18.739A81.448,81.448,0,0,1,2.676,246.5l32.838-21.746L55.5,211.511a5,5,0,0,1,6.932,1.414l39.03,58.931A5.007,5.007,0,0,1,100.052,278.793Zm0,0" transform="translate(-1.41 -136.569)" />
                    <path className="e" d="M160.566,278.793l-19.6,12.98L96.027,224.751l19.99-13.239a5,5,0,0,1,6.932,1.414l39.03,58.929A5.008,5.008,0,0,1,160.566,278.793Zm0,0" transform="translate(-61.924 -136.569)" />
                    <g transform="translate(133.54 34.704)">
                      <path className="f" d="M400.55,100.346l-15.46,8.273a5.007,5.007,0,0,1-6.387-1.437L390.891,99.3a.116.116,0,0,0,.15.058,10.487,10.487,0,0,1,3.766-.7A10.6,10.6,0,0,1,400.55,100.346Zm0,0" transform="translate(-378.703 -98.656)" />
                      <path className="f" d="M426.562,155.336l-15.123,8.087a5,5,0,0,1-6.388-1.432l12.188-7.887.526-.247a.224.224,0,0,0,.3.059,10.814,10.814,0,0,1,2.557-.3A11.093,11.093,0,0,1,426.562,155.336Zm0,0" transform="translate(-395.783 -134.281)" />
                      <path className="f" d="M455.127,210.1l-13.32,7.126a5.013,5.013,0,0,1-6.388-1.437l10.559-6.832a.168.168,0,0,0,.209-.008A10.324,10.324,0,0,1,455.127,210.1Zm0,0" transform="translate(-415.467 -169.803)" />
                    </g>
                  </g>
                  <path className="g" d="M64.727-1.849S57.254,23.085,40.745,40.258-1.308,66.84-1.308,66.84" transform="translate(1253.5 412.5)" />
                  <path className="g" d="M1.555,7.444A47.508,47.508,0,0,1,0,16" transform="translate(1319.5 388.5)" />
                  <line className="g" x2="14" y2="5" transform="translate(1177.5 283.5)" />
                  <line className="g" x2="9" y2="10" transform="translate(1191.5 266.5)" />
                  <line className="g" x2="3" y2="15" transform="translate(1210.5 256.5)" />
                  <path className="g" d="M16,0A92.3,92.3,0,0,0,5.146,21.313C1.146,33.063,0,47,0,47" transform="translate(1128.5 331.5)" />
                  <path className="g" d="M1.555,7.444A47.508,47.508,0,0,1,0,16" transform="translate(1132.256 401.276) rotate(164)" />
                </g>
              </svg>
            </div>
          </Col>
          <Col sm={16}>
            <div>
              <h4 style={{ fontSize: 30, fontWeight: "bold" }}>Permohonan Terkirim!</h4>
              <p>
Permohonan anda telah terkirim, petugas akan segera
                mengecek dan datang untuk mesurvei lokasi permohonan anda.

              </p>
              <Flex alignItems="center" guter={8} justifyContent="space-between" style={{ marginTop: 40 }}>
                <div>
                  <Button type="primary" size="large">Cetak Permohonan</Button>
                </div>
                <div>
                  <Link to="/citizen">
                    <Button type="link">Kembali ke beranda</Button>
                  </Link>
                </div>
              </Flex>
            </div>
          </Col>
        </Row>
      </Modal>
    </MakeRequestStyled>
  );
};

export default Form.create({ name: "make-request" })(MakeRequest);
