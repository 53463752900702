/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import PropTypes from "prop-types";

const LayoutFluid = ({ children }) => (
  <div>
    {children}
  </div>
);

export default LayoutFluid;

LayoutFluid.propTypes = {
  children: PropTypes.node.isRequired,
};
