import React from "react";
import { Button, Icon } from "antd";
import { Link } from "@reach/router";
import { maxSM, minSM } from "utils";
import step1 from "images/step1.png";
import step2 from "images/step2.png";
import step3 from "images/step3.png";
import PropTypes from "prop-types";
import styled from "styled-components";
import Flex from "../flex";
import Logo from "../logo";

export const tableNoBorder = `
  tr {
    border: none;
    background: #fff;
  }
`;

export const Title = styled.h3`
  font-size: 40px;
  color: #212121;
`;

export const StepItem = styled.div`
  text-align: center;
  p {
    margin-top: 20px;
    color: #000000;
  }
`;


export const BannerHero = () => (
  <div css={`
    ${maxSM} {
      padding-top: 40px;
    }
  `}
  >
    <h3 style={{ fontSize: 30, color: "#9F9F9F" }}>Apa itu</h3>
    <div
      style={{ marginBottom: 40 }}
      css={`
      ${minSM} {
        padding-left: 20px;
      }
    `}
    >
      <Logo fontSize={40} lastText="?" />
    </div>
    <div>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        ccusamus commodi cumque distinctio ipsum modi
        quae voluptas!
      </p>
      <Link to="/citizen/permohonan/new">
        <Button
          type="primary"
          size="large"
          block
          style={{ marginTop: 20 }}
          css={`
          ${minSM} {
            min-width: 300px;
          }
        `}
        >
Ajukan Permohonan

        </Button>
      </Link>
    </div>
  </div>
);

export const Process = () => (
  <Flex
    justifyContent="space-between"
    alignItems="baseline"
    css={`
    ${maxSM} {
      display: block;
      i {
        display: none;
      }
      ${StepItem} {
        margin-bottom: 40px;
      }
    }
`}>
    <div>
      <StepItem>
        <img src={step1} alt="" />
        <p>Isi Form Pengajuan</p>
      </StepItem>
    </div>
    <span><Icon style={{ fontSize: 30 }} type="arrow-right" /></span>
    <div>
      <StepItem>
        <img src={step2} alt="" />
        <p>Verifikasi Surveyor</p>
      </StepItem>
    </div>
    <span><Icon style={{ fontSize: 30 }} type="arrow-right" /></span>
    <div>
      <StepItem>
        <img src={step3} alt="" />
        <p>Surat Permohonan</p>
      </StepItem>
    </div>
  </Flex>
);

export const DocStyled = styled.div`
  background: #fff;
  padding: 10px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    & > span {
      padding: 6px 20px;
      display: block;
      font-weight: bold;
    }
    & > li {
      &:nth-of-type(odd) {
        background: #E7EBE7;
      }
      & > div {
        padding: 6px 20px;
        & > span {
          &:nth-of-type(2) {
            padding-left: 10px;
          }
        }
      }
      & > ul {
        & > li {
          &:nth-of-type(odd) {
            background: #fff;
          }
          & > div > span {
            &:first-of-type {
              padding-left: 20px;
            }
          }
          & > span {
            padding-left: 20px;
          }
          & > ul {
            & > li {
              & > div {
                padding: 6px 20px;
                & > span {
                  &:first-of-type {
                    padding-left: 40px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ListItem = ({ title, value }) => (
  <div style={{
    display: "flex",
  }}
  >
    <span style={{ flexBasis: "40%", fontWeight: !value && "bold" }}>{title}</span>
    {
      value && (
        <span>
          {value}
        </span>
      )
    }
  </div>
);

ListItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
