import React from "react";
import {
  Layout,
} from "antd";
import { Container, Header } from "components";
import PropTypes from "prop-types";
import { ContentStyled, ContentInner } from "./styled";

const LayoutSurveyor = ({ children }) => (
  <>
    <Layout style={{ background: "#fff" }}>
      <Header />
      <ContentStyled>
        <Layout style={{ background: "#fff" }}>
          <Container style={{ width: "100%", maxWidth: 992 }}>
            <ContentInner>{children}</ContentInner>
          </Container>
        </Layout>
      </ContentStyled>
    </Layout>
  </>
);

LayoutSurveyor.propTypes = {
  children: PropTypes.node.isRequired,
};

// eslint-disable-next-line react/prop-types
LayoutSurveyor.Fluid = ({ children }) => (
  <>
    <Layout style={{ background: "#fff" }}>
      <Header />
      <ContentStyled style={{ padding: "40px 0", paddingBottom: 0 }}>
        <Layout style={{ background: "#fff" }}>
          <div>
            {children}
          </div>
        </Layout>
      </ContentStyled>
    </Layout>
  </>
);

export default LayoutSurveyor;
