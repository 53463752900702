/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import {
  Layout, Menu,
} from "antd";
import { getRole } from "utils";
import { Link } from "@reach/router";
import PropTypes from "prop-types";
import Logo from "../logo";
import { MenuItem } from "./styled";

const { Sider } = Layout;

const { SubMenu } = Menu;

const Sidebar = ({ location, toggle }) => {
  const [menus] = useState({
    0: {
      title: "Dashboard",
      path: "admin",
      subPath: "",
    },
    1: {
      title: "Peruntukan",
      path: "admin",
      subPath: "peruntukan",
    },
    2: {
      title: "Pengguna",
      path: "admin",
      subPath: "user",
      menus: [
        {
          title: "Masyarakat",
          path: "citizen",
        },
        {
          title: "Surveyor",
          path: "surveyor",
        },
      ],
    },
    3: {
      title: "Permohonan",
      path: "admin",
      subPath: "permohonan",
    },
    4: {
      title: "Pejabat Pengesah",
      path: "admin",
      subPath: "pejabat",
    },
  });

  const path = location.pathname.split("/")[2];

  return (
    <Sider
      width={200}
      style={{
        background: "#fff",
        boxShadow: "0px 1px 6px #00000029",
        position: "fixed",
        height: "100vh",
        left: toggle ? -200 : 0,
      }}
    >
      <div style={{ padding: "40px 20px" }}>
        <Link to={`/${getRole() || ""}`}>
          <Logo />
        </Link>
      </div>
      <Menu
        mode="inline"
        selectedKeys={[path || "/"]}
      >
        {
          Object.keys(menus).map((key, i) => {
            if (menus[key].menus) {
              return (
                <SubMenu
                  key={`sub-${i}`}
                  title={(
                    <span style={{ textTransform: "uppercase" }}>{menus[key].title}</span>
                )}
                >
                  {
                  menus[key].menus.map((childItem) => (
                    <MenuItem key={childItem.path}>
                      <Link to={`/${menus[key].path}/${menus[key].subPath}/${childItem.path}`}>{childItem.title}</Link>
                    </MenuItem>
                  ))
                }
                </SubMenu>
              );
            }
            return (
              <MenuItem key={menus[key].subPath}>
                <Link to={`/${menus[key].path}/${menus[key].subPath}`}>{menus[key].title}</Link>
              </MenuItem>
            );
          })
        }
      </Menu>
    </Sider>
  );
};

Sidebar.propTypes = {
  location: PropTypes.shape.isRequired,
  toggle: PropTypes.bool.isRequired,
};

export default Sidebar;
