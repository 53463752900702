import React from "react";
import styled from "styled-components";
import { maxSM } from "utils";
import PropTypes from "prop-types";

const LogoStyled = styled.div`
  h1 {
    font-size: 28px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0;
    margin: 0;
    span {
      color: ${({ theme }) => theme.colorPrimary};
    }
    ${maxSM} {
      font-size: 18px;
    }
  }
`;

const Logo = ({ lastText, fontSize, firstColor }) => (
  <LogoStyled>
    <h1 style={{ fontSize, color: firstColor }}>
Advice
      <span> Planning</span>
      {" "}
      {lastText && lastText}
    </h1>
  </LogoStyled>
);

Logo.propTypes = {
  firstColor: PropTypes.string.isRequired,
  fontSize: PropTypes.string.isRequired,
  lastText: PropTypes.string.isRequired,
};

export default Logo;
