/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { Location } from "@reach/router";
import { PrivateRoute } from "components";
import LayoutSidebar from "./layout-sidebar";
import LayoutFluid from "./layout-fluid";
import LayoutSurveyor from "./layout-surveyor";
import LayoutAdmin from "./layout-admin";

const Layout = ({ children }) => (
  <Location>
    {({ location }) => {
      const paths = location.pathname.split("/");
      switch (paths[1]) {
        case "admin": {
          if (paths[4] === "rekomendasi") {
            return (
              <PrivateRoute {...{ location }} userRole="admin" component={LayoutFluid}>
                {children}
              </PrivateRoute>
            );
          }
          return (
            <PrivateRoute {...{ location }} userRole="admin" component={LayoutAdmin}>
              {children}
            </PrivateRoute>
          );
        }
        case "surveyor": {
          if (paths[3] === "maps") {
            return (
              <PrivateRoute {...{ location }} userRole="surveyor" component={LayoutSurveyor.Fluid}>
                {children}
              </PrivateRoute>
            );
          }
          return (
            <PrivateRoute {...{ location }} userRole="surveyor" component={LayoutSurveyor}>
              {children}
            </PrivateRoute>
          );
        }
        case "permohonan": {
          if (paths[2]) {
            return (
              <PrivateRoute {...{ location }} userRole="citizen" component={LayoutFluid}>
                {children}
              </PrivateRoute>
            );
          }
          return (
            <PrivateRoute {...{ location }} userRole="citizen" component={LayoutSidebar}>
              {children}
            </PrivateRoute>
          );
        }
        case "login": {
          return (
            <LayoutFluid {...{ location }}>
              {children}
            </LayoutFluid>
          );
        }
        case "citizen": {
          if (paths[3] === "new") {
            return (
              <PrivateRoute {...{ location }} userRole="citizen" component={LayoutFluid}>
                {children}
              </PrivateRoute>
            );
          }
          return (
            <PrivateRoute {...{ location }} userRole="citizen" component={LayoutSidebar}>
              {children}
            </PrivateRoute>
          );
        }

        default:
          return (
            <div>
              {children}
            </div>
          );
      }
    }}
  </Location>
);

export default Layout;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
