import React, { useEffect, useState } from "react";
import {
  Card, Flex, Skeleton, SEO,
} from "components";
import {
  Button, Input, Modal, Table, Form,
} from "antd";
import doc from "images/document.png";
import { tableNoBorder } from "components/utils";
import { globalFetch, ADMIN_FUNCTIONARY, globalPost } from "utils/api";
import PropTypes from "prop-types";

const FunctionaryList = ({ form }) => {
  const [visible, setVisible] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [idEdit, setIdEdit] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    setLoading(true);
    globalFetch({ path: ADMIN_FUNCTIONARY })
      .then((_data) => {
        setLoading(false);
        setData(_data.functionaries);
      });
  }, [refetch]);

  const handleEditFunctionary = (values) => {
    setVisible(true);
    setIdEdit(values.id);
    setTimeout(() => {
      Object.keys(values).map((key) => {
        form.setFieldsValue({ [key]: values[key] });
      });
    }, 300);
  };

  const handleDelete = (values) => {
    Modal.confirm({
      title: "Hapus Data Pejabat",
      content: `Apakah kamu yakin ingin menghapus data ${values.name}`,
      okText: "Ya",
      okType: "danger",
      cancelText: "Tidak",
      onOk() {
        const formData = new FormData();
        formData.append("_method", "DELETE");
        globalPost({ path: `${ADMIN_FUNCTIONARY}/${values.id}`, body: formData })
          .then(() => {
            Modal.success({
              content: "Hapus data berhasil",
            });
            setRefetch(!refetch);
          });
      },
    });
  };

  const columns = [
    {
      title: "NIP",
      dataIndex: "identification_number",
      key: "identification_number",
    },
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Jabatan",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "right",
      render: (_, values) => (
        <Flex justifyContent="flex-end" gutter={8}>
          <div>
            <Button onClick={() => handleEditFunctionary(values)}>Edit</Button>
          </div>
          <div>
            <Button onClick={() => handleDelete(values)} type="danger">Hapus</Button>
          </div>
        </Flex>
      ),
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        setLoadingSubmit(true);
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("identification_number", values.identification_number);
        formData.append("position", values.position);
        if (idEdit) {
          formData.append("_method", "patch");
        }
        globalPost({ path: `${ADMIN_FUNCTIONARY}${idEdit ? `/${idEdit}` : ""}`, body: formData })
          .then(() => {
            setLoadingSubmit(false);
            if (idEdit) {
              Modal.success({
                content: "Edit pejabat berhasil",
              });
            }
            setVisible(false);
            setIdEdit(null);
            setRefetch(!refetch);
          });
      }
    });
  };

  const { getFieldDecorator } = form;

  const handleEdit = () => {
    setVisible(true);
  };

  return (
    <Card
      title="Daftar Pejabat Pengesah"
      extra={<Button block onClick={handleEdit} type="primary" size="large" style={{ textTransform: "uppercase" }}>Tambah Pejabat</Button>}
      style={{ padding: 24 }}
    >
      <SEO title="Pejabat" />
      {
        data && (
          <div>
            <Table
              css={tableNoBorder}
              loading={loading}
              showHeader={false}
              dataSource={data}
              columns={columns}
            />
          </div>
        )
      }
      {
        !data && loading && (
          <div>
            <Skeleton height={20} marginBottom={20} />
            <Skeleton height={20} marginBottom={20} />
            <Skeleton height={20} marginBottom={20} />
          </div>
        )
      }
      {
        data && (
          !data.length && (
            <Flex justifyContent="center" style={{ height: "50vh" }} alignItems="center">
              <div style={{ textAlign: "center" }}>
                <img src={doc} alt="" />
                <p style={{ marginTop: 20 }}>Belum ada data peruntukan</p>
                <Button onClick={() => setVisible(true)} type="primary" size="large" style={{ marginTop: 20, textTransform: "uppercase" }}>Tambah Peruntukan</Button>
              </div>
            </Flex>
          )
        )
      }
      <Modal
        visible={visible}
        footer={null}
        onOk={handleSubmit}
        showHeader={false}
        destroyOnClose
        onCancel={() => setVisible(false)}
      >
        <div>
          <h4 style={{
            color: "#005083",
            fontSize: 28,
            fontWeight: "bold",
            textAlign: "center",
          }}
          >
            {idEdit ? "Edit" : "Tambah"}
            {" "}
Pejabat Pengesah
          </h4>
        </div>
        <Form onSubmit={handleSubmit} style={{ margin: "0 auto", maxWidth: 320 }}>
          <Form.Item colon={false} label="NIP">
            {getFieldDecorator("identification_number", {
              rules: [{ required: true, message: "Please input your NIP!" }],
            })(
              <Input placeholder="NIP" />,
            )}
          </Form.Item>
          <Form.Item colon={false} label="Nama">
            {getFieldDecorator("name", {
              rules: [{ required: true, message: "Please input your name!" }],
            })(
              <Input placeholder="Nama" />,
            )}
          </Form.Item>
          <Form.Item colon={false} label="Jabatan">
            {getFieldDecorator("position", {
              rules: [{ required: true, message: "Please input your position!" }],
            })(
              <Input placeholder="Jabatan" />,
            )}
          </Form.Item>
          <div>
            <Button block type="primary" htmlType="submit" loading={loadingSubmit} size="large" style={{ marginTop: 20, textTransform: "uppercase" }}>Simpan</Button>
            <Button block type="link" size="large" onClick={() => setVisible(false)} style={{ marginTop: 20, textTransform: "uppercase", color: "#ff4d4f" }}>Batal</Button>
          </div>
        </Form>
      </Modal>
    </Card>
  );
};

FunctionaryList.propTypes = {
  form: PropTypes.shape.isRequired,
};

export default Form.create({ name: "functionary" })(FunctionaryList);
