/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import {
  Form, Button, Alert, Modal,
} from "antd";
import { Logo } from "components";
import { globalPost, CITIZEN_REGISTER, globalFetch } from "utils/api";
import { Link } from "@reach/router";
import {
  setCookie, getToken, getCookie, getRole,
} from "utils";
import {
  CardStyled, Input, InputPassword, CardFooter,
} from "./styled";

const SignupCard = ({ form }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!getCookie("roles")) {
      globalFetch({ path: "/role" })
        .then((data) => {
          setCookie("roles", JSON.stringify(data.roles));
        });
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (error) {
        setError(null);
      }
      if (!err) {
        setLoading(true);
        const formData = JSON.stringify(values);
        globalPost({ path: CITIZEN_REGISTER, body: formData, headers: { "Content-Type": "application/json" } })
          .then((data) => {
            Modal.info({
              title: "Registrasi Berhasil",
              content: (
                <div>
                  <p>
Email
                    {" "}
                    {data.user.email}
                    {" "}
berhasil terdaftar
                  </p>
                </div>
              ),
              onOk() {
                window.location.href = "/login";
              },
            });
            setLoading(false);
          })
          .catch((_err) => {
            setLoading(false);
            if (_err.status === 401) {
              setError("Terjadi kelasalahan hak ases!");
            }
            if (_err.status === 422) {
              setError("Email anda sudah terdaftar");
            }
          });
      }
    });
  };

  const { getFieldDecorator } = form;

  if (getToken()) {
    const roleName = getRole();

    if (roleName) {
      window.location.href = `/${roleName}`;
      return null;
    }
  }

  return (
    <CardStyled>
      <div style={{ textAlign: "center", marginBottom: 50 }}>
        <Logo />
      </div>
      <Form onSubmit={handleSubmit}>
        <div style={{ marginBottom: 20 }}>
          <span style={{ fontSize: 20, textTransform: "uppercase", fontWeight: "bold" }}>
Sign Up
            {" "}
            <span style={{ color: "#00b515" }}>Citizen</span>
          </span>
        </div>
        {
          error && <Alert closable style={{ marginBottom: 20 }} message={error} type="error" />
        }
        <Form.Item>
          {getFieldDecorator("nik", {
            rules: [{ required: true, message: "Please input your nik!" }],
          })(
            <Input placeholder="NIK" />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("name", {
            rules: [{ required: true, message: "Please input your name!" }],
          })(
            <Input placeholder="Nama" />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("email", {
            rules: [{ required: true, message: "Please input your email!" }],
          })(
            <Input type="email" placeholder="Email" />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("phone", {
            rules: [{ required: true, message: "Please input your phone!" }],
          })(
            <Input placeholder="Telephone" />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("password", {
            rules: [{ required: true, message: "Please input your password!" }],
          })(
            <InputPassword placeholder="Password" />,
          )}
        </Form.Item>
        <Button type="primary" loading={loading} htmlType="submit" block size="large" style={{ textTransform: "uppercase" }}>Daftar</Button>
        <CardFooter>
          <span>
Sudah punya akun?
            <Link to="/login">
              <a> Login</a>
            </Link>
          </span>
        </CardFooter>
      </Form>
    </CardStyled>
  );
};

export default Form.create({ name: "signup" })(SignupCard);
