import React from "react";
import PropTypes from "prop-types";

const Surveyor = ({ children }) => (
  <div>
    {children}
  </div>
);

Surveyor.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Surveyor;
