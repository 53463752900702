import styled from "styled-components";
import { Menu } from "antd";

export const MenuItem = styled(Menu.Item)`
  &&& {
    height: 52px;
    line-height: 54px;
    text-transform: uppercase;
    &:after {
      display: none;
    }
  }
`;