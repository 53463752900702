import React, { useRef } from "react";
import {
  Layout, Button, Dropdown, Menu,
} from "antd";
import { Flex, Logo } from "components";
import {
  maxSM, removeCookie, getRole, getUser,
} from "utils";
import { globalPost, LOGOUT } from "utils/api";
import { useOvermind } from "overmind-state";
import { Link } from "@reach/router";
import styled from "styled-components";
import Avatar from "../avatar";

const getMenus = (handleLogout) => (
  <Menu style={{ minWidth: 200 }}>
    <Menu.Item key="0">
      <a href="#">Setting</a>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item key="1" onClick={handleLogout}>Log Out</Menu.Item>
  </Menu>
);

const HeaderAdmin = styled.header`
  background: ${({ theme }) => theme.colorPrimary};
  padding: 0 22px;
  position: fixed;
  left: ${({ toggle }) => (toggle ? 0 : 200)}px;
  right: 0;
  top: 0;
  transition: all 0.2s;
  z-index: 999;
`;

const HeaderAnt = Layout.Header;

const HeaderStyled = styled(HeaderAnt)`
  &&& {
    background: #fff;
    padding-top: 20px;
    ${maxSM} {
      padding: 0 16px;
    }
  }
`;

const Header = () => {
  const user = getUser();

  const handleLogout = () => {
    globalPost({ path: LOGOUT })
      .then(() => {
        removeCookie();
        window.location.href = "/login";
      });
  };
  return (
    <HeaderStyled>
      <Flex justifyContent="space-between" alignItems="center">
        <div>
          <Link to={`/${getRole() || ""}`}>
            <Logo />
          </Link>
        </div>
        <div>
          <Dropdown overlay={getMenus(handleLogout)} trigger={["click"]}>
            <div style={{ cursor: "pointer" }}>
              <Avatar name={user.name || "Pengguna"} />
            </div>
          </Dropdown>
        </div>
      </Flex>
    </HeaderStyled>
  );
};

Header.Admin = ({ toggle }) => {
  const headerRef = useRef(null);
  const { state, actions } = useOvermind();

  const handleLogout = () => {
    globalPost({ path: LOGOUT })
      .then(() => {
        removeCookie();
        window.location.href = "/login";
      });
  };

  return (
    <>
      <HeaderAdmin ref={headerRef} toggle={toggle}>
        <Flex alignItems="center" justifyContent="space-between" style={{ height: 68 }}>
          <div>
            <Button type="link" style={{ color: "#fff" }} icon="menu" onClick={actions.handleAdminNavToggle} />
          </div>
          <div>
            <div>
              <Dropdown overlay={() => getMenus(handleLogout)} trigger={["click"]} getPopupContainer={() => headerRef.current}>
                <div style={{ cursor: "pointer" }}>
                  <Avatar style={{ backgroundColor: "#87d068" }} icon="user" />
                </div>
              </Dropdown>
            </div>
          </div>
        </Flex>
      </HeaderAdmin>
      <div style={{ height: 68 }} />
    </>
  );
};

export default Header;
