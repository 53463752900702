/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Form, Button, Alert } from "antd";
import { Logo } from "components";
import { globalPost, LOGIN, globalFetch } from "utils/api";
import { Link } from "@reach/router";
import {
  setToken, setCookie, getToken, getCookie, getRole,
} from "utils";
import {
  CardStyled, ForgotWrap, CardFooter, Input, InputPassword,
} from "./styled";

const LoginCard = ({ form }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!getCookie("roles")) {
      globalFetch({ path: "/role" })
        .then((data) => {
          setCookie("roles", JSON.stringify(data.roles));
        });
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (error) {
        setError(null);
      }
      if (!err) {
        setLoading(true);
        const formData = new FormData();
        formData.append("email", values.email);
        formData.append("password", values.password);

        globalPost({ path: LOGIN, body: formData })
          .then((data) => {
            setToken(data.access_token);
            setCookie("user", JSON.stringify(data.user));
            setLoading(false);
          })
          .catch((_err) => {
            setLoading(false);
            if (_err.status === 401) {
              setError("Email atau password Anda salah!");
            }
          });
      }
    });
  };

  const { getFieldDecorator } = form;

  if (getToken()) {
    const roleName = getRole();

    if (roleName) {
      window.location.href = `/${roleName}`;
      return null;
    }
  }

  return (
    <CardStyled>
      <div style={{ textAlign: "center", marginBottom: 50 }}>
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <Form onSubmit={handleSubmit}>
        <div style={{ marginBottom: 20 }}>
          <span style={{ fontSize: 20, textTransform: "uppercase", fontWeight: "bold" }}>Login In</span>
        </div>
        {
          error && <Alert closable style={{ marginBottom: 20 }} message={error} type="error" />
        }
        <Form.Item>
          {getFieldDecorator("email", {
            rules: [{ required: true, message: "Please input your username!" }],
          })(
            <Input type="email" placeholder="Email" />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("password", {
            rules: [{ required: true, message: "Please input your password!" }],
          })(
            <InputPassword placeholder="Password" />,
          )}
        </Form.Item>
        <ForgotWrap>
          <a href="#">
            <span>Lupa Password</span>
          </a>
        </ForgotWrap>
        <Button type="primary" loading={loading} htmlType="submit" block size="large" style={{ textTransform: "uppercase" }}>Login</Button>
        <CardFooter>
          <span>
Belum punya akun?
            <Link to="/signup">
              <a> Sign Up</a>
            </Link>
          </span>
        </CardFooter>
      </Form>
    </CardStyled>
  );
};

export default Form.create({ name: "login" })(LoginCard);
