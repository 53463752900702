import styled from "styled-components";
import { Button } from "antd";

export const ButtonStatus = styled(Button)`
  border: 1px solid ${({ theme }) => theme.colorRed};
  color: ${({ theme }) => theme.colorRed};
`;

export const UserContentWrap = styled.div`
  max-width: 576px;
  margin: 0 auto;
`;

export const UserContentChild = styled.div`
  margin-top: 40px;
`;

export const Title = styled.h3`
  font-size: 40px;
  color: #212121;
`;

export const StepItem = styled.div`
  text-align: center;
  p {
    margin-top: 20px;
    color: #000000;
  }
`;
