import React, { useEffect, useState } from "react";
import { Table, Tag } from "antd";
import { Button } from "components";
import styled from "styled-components";
import { Link } from "@reach/router";
import { maxSM } from "utils";
import { SURVEYOR_REC_REQUEST, globalFetch } from "utils/api";

export const RegisterNumber = styled.span`
  ${maxSM} {
    span {
      display: none;
    }
  }
`;

const RequestList = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    globalFetch({ path: SURVEYOR_REC_REQUEST })
      .then((_data) => {
        setLoading(false);
        setData(_data.recommendationRequests);
      });
  }, []);

  const columns = [
    {
      title: () => (
        <RegisterNumber>
No
          {" "}
          <span>Pendaftaran</span>
          {" "}
        </RegisterNumber>
      ),
      dataIndex: "land_certificate_number",
      key: "land_certificate_number",
    },
    {
      title: "Nama",
      dataIndex: "requestor_name",
      key: "requestor_name",
      align: "center",
    },
    {
      title: "No. HP",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      render: (val) => (
        val || "-"
      ),
    },
    {
      title: "Status",
      dataIndex: "recommendation_request_status",
      key: "recommendation_request_status",
      align: "center",
      render: (val) => (
        <Tag color="green" style={{ textTransform: "uppercase" }}>{val.caption}</Tag>
      ),
    },
    {
      title: "Aksi",
      dataIndex: "aksi",
      align: "right",
      key: "aksi",
      render: (_, values) => (
        values.recommendation_request_status.name === "requested" ? (
          <Link to={`/surveyor/${values.id}/maps`}>
            <Button.Blue type="primary">Survei</Button.Blue>
          </Link>
        ) : <Button disabled>Survei</Button>
      ),
    },
  ];
  return (
    <div>
      <h3 style={{ fontWeight: "bold", fontSize: 20, marginBottom: 10 }}>Daftar Pemohon</h3>
      <Table dataSource={data} loading={loading} columns={columns} scroll={{ x: 800 }} />
    </div>
  );
};

export default RequestList;
