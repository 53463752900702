/* eslint-disable react/prop-types */
import React from "react";
import { BackgroundImage } from "components";
import bgLogin from "images/bg-login.jpg";
import LoginCard from "components/login/card";
import SignUpCard from "components/signup/card";

const Login = ({ path }) => (
  <BackgroundImage background={bgLogin}>
    <div style={{
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: 16,
      paddingRight: 16,
    }}
    >
      {
          path === "login" ? (
            <LoginCard />
          ) : (
            <SignUpCard />
          )
        }
    </div>
  </BackgroundImage>
);

export default Login;
