/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { SidebarAdmin } from "components/sidebars";
import { Layout } from "antd";
import { useOvermind } from "overmind-state";
import { Header } from "components";

const LayoutAdmin = ({ location, children }) => {
  const { state } = useOvermind();
  useEffect(() => window.scrollTo(0, 0), [location.pathname]);

  return (
    <>
      <Layout>
        <SidebarAdmin location={location} toggle={state.adminNavToggle} />
        <Layout style={{ background: "#f4f4f4", marginLeft: state.adminNavToggle ? 0 : 200, minHeight: "100vh" }}>
          <Header.Admin toggle={state.adminNavToggle} />
          <div>{children}</div>
        </Layout>
      </Layout>
    </>
  );
};

export default LayoutAdmin;
