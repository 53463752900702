/* eslint-disable import/no-unresolved */
import React, { useEffect, useState, useContext } from "react";
import { Maps } from "components";
import { RequestContext } from "contexts/request-context";
import PropTypes from "prop-types";
import { SURVEYOR_SURVEY_REC_REQUEST, globalPost } from "utils/api";

// const dum = {
//   id: 26,
//   datetime: "2019-12-16 04:27:38",
//   requestor_user_id: 4,
//   requestor_name: "Muhammad Rusdi",
//   requestor_position: "Direktur",
//   allotment_id: 7,
//   recommendation_request_status_id: 4,
//   requested_activity: "Kantor Pusat",
//   latitude: "-405987.6347093297",
//   longitude: "13310639.44290635",
//   land_area: 31,
//   building_height: 31,
//   land_certificate_number: "31",
//   created_at: "2019-12-15 20:27:38",
//   updated_at: "2019-12-15 20:28:23",
//   surveyor_user_id: 3,
//   phone: null,
//   institution_name: "PT Blabla",
// };

const TrackMaps = ({ id }) => {
  const [data, setData] = useState(null);
  const {
    idArea, setExpand, coordinate, setCoordinate, setProperties,
  } = useContext(RequestContext);

  useEffect(() => {
    globalPost({
      path: `${SURVEYOR_SURVEY_REC_REQUEST}/${id}/takeSurvey`,
      body: JSON.stringify({
        _method: "PATCH",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((dataPrivate) => {
        setData(dataPrivate.recommendationRequest);
      });
  }, []);

  return (
    <>
      <Maps
        isSurveyor
        idArea={idArea}
        dataSurveyor={data && data}
        drawable={null}
        isEdit={null}
        handleDrawable={null}
        handleExpand={setExpand}
        drawCancel={null}
        centerArea={null}
        handleDrawCancel={null}
        handleProperties={setProperties}
        modalVisible={null}
        center={{ coordinate, setCoordinate }}
        handleModalVisible={null}
        dataFeatures={data && data}
        handleCoordinates={null}
        style={{ height: "calc(100vh - 96px)" }}
      />
    </>
  );
};

TrackMaps.propTypes = {
  id: PropTypes.string.isRequired,
};

export default TrackMaps;
