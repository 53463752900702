/* eslint-disable import/no-unresolved */
import React from "react";
import RequestContext from "contexts/request-context";
import PropTypes from "prop-types";

const UserWrap = ({ children }) => (
  <RequestContext>
    {children}
  </RequestContext>
);

UserWrap.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserWrap;
