/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import React, { useState, useContext, useEffect } from "react";
import { Maps } from "components";
import { AllotmentContext } from "contexts/allotment-context";
import { navigate } from "@reach/router";
import { globalPost, ALLOTMENTS_AREA } from "utils/api";
import PropTypes from "prop-types";
import {
  Button, Input, Modal, Form, InputNumber,
} from "antd";

const AllotmentMaps = ({
  id, type, edit, form,
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [centerArea, setCenterArea] = useState(null);
  const {
    drawable,
    modalVisible,
    drawCancel,
    setProperties,
    areas,
    coordinates,
    setCoordinates,
    setDrawCancel,
    setModalVisible,
    setDrawable,
  } = useContext(AllotmentContext);
  const isEdit = type === "edit";

  if (type === "edit" && !areas) {
    window.location.href = `/admin/peruntukan/${id}`;
  }

  useEffect(() => {
    if (areas && edit) {
      const newAreas = areas.map((item, i) => {
        const { coordinates, ...props } = item;
        const newItem = {
          type: "Feature",
          id: i,
          geometry: {
            type: "Polygon", coordinates: [coordinates.map((item) => [parseFloat(item.latitude), parseFloat(item.longitude)])],
          },
          properties: {
            ...props,
          },
        };
        return newItem;
      });

      const currentArea = newAreas.filter((item) => item.properties.id === Number(edit));
      setData(newAreas);
      setCenterArea(currentArea);
      setModalVisible(true);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        setLoading(true);
        console.log("Received values of form: ", values);
        values.allotment_id = id;
        values.center_coordinate = coordinates.center_coordinate;
        values.coordinates = coordinates.coordinates;
        if (isEdit) {
          values._method = "PATCH";
        }
        const body = JSON.stringify(values);
        globalPost({ path: `${ALLOTMENTS_AREA}${isEdit ? `/${edit}` : ""}`, body, headers: { Accept: "application/json", "Content-Type": "application/json" } })
          .then(() => {
            setLoading(false);
            navigate(`/admin/peruntukan/${id}`);
          });
      }
    });
  };

  const { getFieldDecorator } = form;

  const setNewValue = (name) => {
    const obj = {};

    if (isEdit) {
      obj[name] = centerArea && centerArea[0].properties[name];
    }

    return obj[name];
  };

  return (
    <div>
      <Maps
        type="admin"
        idArea={edit}
        drawable={drawable}
        isEdit={isEdit}
        handleDrawable={setDrawable}
        drawCancel={drawCancel}
        centerArea={centerArea}
        handleDrawCancel={setDrawCancel}
        modalVisible={modalVisible}
        center={data && data[0].center_coordinate}
        handleModalVisible={setModalVisible}
        handleProperties={setProperties}
        dataFeatures={data && data}
        handleCoordinates={setCoordinates}
        style={{ height: "calc(100vh - 68px)" }}
      />
      <Modal
        title={<h3 style={{ margin: 0, fontWeight: "bold" }}>Syarat Permohonan Area</h3>}
        visible={modalVisible}
        footer={null}
        onOk={handleSubmit}
        destroyOnClose
        onCancel={() => setModalVisible(false)}
      >
        <Form onSubmit={handleSubmit} layout="horizontal" labelAlign="left">
          <Form.Item label="KDB Maksimal">
            {getFieldDecorator("kbd_max", {
              rules: [{ required: true, message: "KDB Maksimal tidak boleh kosong!" }],
              initialValue: setNewValue("kbd_max"),
            })(<Input suffix="%" />)}
          </Form.Item>
          <Form.Item label="KLB Maksimal">
            {getFieldDecorator("klb_max", {
              rules: [{ required: true, message: "KLB Maksimal tidak boleh kosong!" }],
              initialValue: setNewValue("klb_max"),
            })(<Input />)}
          </Form.Item>
          <Form.Item label="KDH Minimum">
            {getFieldDecorator("kdh_min", {
              rules: [{ required: true, message: "KDH Minimum tidak boleh kosong!" }],
              initialValue: setNewValue("kdh_min"),
            })(<Input suffix="%" />)}
          </Form.Item>
          <Form.Item label="Maks Jumlah Lantai">
            {getFieldDecorator("max_floor", {
              rules: [{ required: true, message: "Jumalh lantai tidak boleh kosong!" }],
              initialValue: setNewValue("max_floor"),
            })(<InputNumber suffix="Lantai" />)}
            <span style={{ marginLeft: 10 }}>Lantai</span>
          </Form.Item>
          <Form.Item label="Keterangan">
            {getFieldDecorator("information", {
              rules: [{ required: true, message: "Keterangan tidak boleh kosong!" }],
              initialValue: setNewValue("information"),
            })(<Input.TextArea />)}
          </Form.Item>
          <div>
            <Button block type="primary" htmlType="submit" loading={loading} size="large" style={{ marginTop: 20, textTransform: "uppercase" }}>Simpan</Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

AllotmentMaps.propTypes = {
  edit: PropTypes.string.isRequired,
  form: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default Form.create({ name: "allotment-maps" })(AllotmentMaps);
