import React, { useEffect, useState } from "react";
import {
  Card, Flex, Skeleton, SEO,
} from "components";
import {
  Button, Input, Modal, Table, Form,
} from "antd";
import doc from "images/document.png";
import { tableNoBorder } from "components/utils";
import { Link } from "@reach/router";
import { globalFetch, ALLOTMENTS, globalPost } from "utils/api";
import PropTypes from "prop-types";

const Allotment = ({ form }) => {
  const [visible, setVisible] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [idEdit, setIdEdit] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(true);

  useEffect(() => {
    setLoading(true);
    globalFetch({ path: ALLOTMENTS })
      .then(() => {
        setLoading(false);
        setData(data.allotments);
      });
  }, [refetch]);

  const handleEditAllotment = (values) => {
    setVisible(true);
    setIdEdit(values.id);
    setTimeout(() => {
      Object.keys(values).map((key) => {
        form.setFieldsValue({ [key]: values[key] });
      });
    }, 300);
  };

  const handleDelete = (values) => {
    Modal.confirm({
      title: "Hapus Peruntukan",
      content: `Apakah kamu yakin ingin menghapus peruntukan ${values.name}`,
      okText: "Ya",
      okType: "danger",
      cancelText: "Tidak",
      onOk() {
        const formData = new FormData();
        formData.append("_method", "DELETE");
        globalPost({ path: `${ALLOTMENTS}/${values.id}`, body: formData })
          .then(() => {
            Modal.success({
              content: "Penghapusan berhasil",
            });
            setRefetch(!refetch);
          });
      },
    });
  };

  const columns = [
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "right",
      render: (_, values) => (
        <Flex justifyContent="flex-end" gutter={8}>
          <div>
            <Link to={`/admin/peruntukan/${values.id}`}>
              <Button>Detail</Button>
            </Link>
          </div>
          <div>
            <Button onClick={() => handleEditAllotment(values)}>Edit</Button>
          </div>
          <div>
            <Button onClick={() => handleDelete(values)} type="danger">Hapus</Button>
          </div>
        </Flex>
      ),
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        setLoadingSubmit(true);
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("description", values.description);
        if (idEdit) {
          formData.append("_method", "PATCH");
        }
        globalPost({ path: `${ALLOTMENTS}${idEdit ? `/${idEdit}` : ""}`, body: formData })
          .then(() => {
            setLoadingSubmit(false);
            if (idEdit) {
              Modal.success({
                content: "Edit peruntukan berhasil",
              });
            }
            setIdEdit(null);
            setVisible(false);
            setRefetch(!refetch);
          });
      }
    });
  };

  const { getFieldDecorator } = form;

  const handleEdit = () => {
    setVisible(true);
  };

  return (
    <Card
      title="Daftar Peruntukan"
      extra={<Button block onClick={handleEdit} type="primary" size="large" style={{ textTransform: "uppercase" }}>Tambah Peruntukan</Button>}
      style={{ padding: 24 }}
    >
      <SEO title="Peruntukan" />
      {
        data && (
          <div>
            <Table
              css={tableNoBorder}
              loading={loading}
              showHeader={false}
              dataSource={data}
              columns={columns}
            />
          </div>
        )
      }
      {
        !data && loading && (
          <div>
            <Skeleton height={20} marginBottom={20} />
            <Skeleton height={20} marginBottom={20} />
            <Skeleton height={20} marginBottom={20} />
          </div>
        )
      }
      {
        data && (
          !data.length && (
            <Flex justifyContent="center" style={{ height: "50vh" }} alignItems="center">
              <div style={{ textAlign: "center" }}>
                <img src={doc} alt="" />
                <p style={{ marginTop: 20 }}>Belum ada data peruntukan</p>
                <Button onClick={() => setVisible(true)} type="primary" size="large" style={{ marginTop: 20, textTransform: "uppercase" }}>Tambah Peruntukan</Button>
              </div>
            </Flex>
          )
        )
      }
      <Modal
        title={`${idEdit ? "Edit" : "Tambah"} Peruntukan`}
        visible={visible}
        footer={null}
        onOk={handleSubmit}
        destroyOnClose
        onCancel={() => setVisible(false)}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item>
            {getFieldDecorator("name", {
              rules: [{ required: true, message: "Please input your name!" }],
            })(
              <Input placeholder="Nama Peruntukan" />,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("description", {
              rules: [{ required: true, message: "Please input your name!" }],
            })(
              <Input.TextArea placeholder="Keterangan" />,
            )}
          </Form.Item>
          <div>
            <Button block type="primary" htmlType="submit" loading={loadingSubmit} size="large" style={{ marginTop: 20, textTransform: "uppercase" }}>Simpan</Button>
          </div>
        </Form>
      </Modal>
    </Card>
  );
};

Allotment.propTypes = {
  form: PropTypes.node.isRequired,
};

export default Form.create({ name: "allotment" })(Allotment);
