import React from "react";
import { Flex } from "components";
import { Icon } from "antd";
import step1 from "images/step1.png";
import step2 from "images/step2.png";
import step3 from "images/step3.png";
import {
  UserContentWrap, Title, StepItem, UserContentChild,
} from "../styled";

const UserRequest = () => (
  <div>
    <UserContentWrap>
      <Title>Proses Pengajuan</Title>
      <UserContentChild>
        <Flex justifyContent="space-between" alignItems="baseline">
          <div>
            <StepItem>
              <img src={step1} alt="" />
              <p>Isi Form Pengajuan</p>
            </StepItem>
          </div>
          <span><Icon style={{ fontSize: 30 }} type="arrow-right" /></span>
          <div>
            <StepItem>
              <img src={step2} alt="" />
              <p>Verifikasi Suveyor</p>
            </StepItem>
          </div>
          <span><Icon style={{ fontSize: 30 }} type="arrow-right" /></span>
          <div>
            <StepItem>
              <img src={step3} alt="" />
              <p>Surat Permohonan</p>
            </StepItem>
          </div>
        </Flex>
      </UserContentChild>
    </UserContentWrap>
  </div>
);

export default UserRequest;
