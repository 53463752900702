/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Flex from "../flex";

const CardStyled = styled.div`
  h3 {
    font-size: 28px;
    font-weight: bold;
    margin: 0;
  }
`;

const Card = ({
  title, extra, children, ...props
}) => (
  <CardStyled {...props}>
    {
        (title || extra) && (
          <Flex alignItems="centent" justifyContent="space-between" style={{ marginBottom: 20 }}>
            <div>
              {title && <h3>{title}</h3>}
            </div>
            <div>
              {extra && extra}
            </div>
          </Flex>
        )
      }
    <div>
      {children}
    </div>
  </CardStyled>
);

Card.propTypes = {
  children: PropTypes.string.isRequired,
  extra: PropTypes.shape.isRequired,
  title: PropTypes.string.isRequired,
};

export default Card;
