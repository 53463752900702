import React, { useState, useEffect } from "react";
import {
  Card, Flex, Skeleton, SEO,
} from "components";
import {
  Button, Input, Table,
} from "antd";
import doc from "images/document.png";
import { Link } from "@reach/router";
import { tableNoBorder } from "components/utils";
import {
  ADMIN_REQUEST, globalFetch,
} from "utils/api";

const { Search } = Input;

const RequestList = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    globalFetch({ path: ADMIN_REQUEST })
      .then((_data) => {
        setLoading(false);
        setData(_data.recommendationRequests);
      });
  }, []);

  const columns = [
    {
      title: "No. Pendaftaran",
      dataIndex: "requestor_user_id",
      key: "requestor_user_id",
    },
    {
      title: "No. Sertifikat",
      dataIndex: "land_certificate_number",
      key: "land_certificate_number",
    },
    {
      title: "Nama",
      dataIndex: "requestor_name",
      key: "requestor_name",
    },
    {
      title: "Jabatan",
      dataIndex: "requestor_position",
      key: "requestor_position",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 180,
      align: "right",
      render: (_, values) => (
        <Flex justifyContent="flex-end" gutter={8}>
          <div>
            <Link to={`/admin/permohonan/${values.id}`}>
              <Button type="primary">Detail</Button>
            </Link>
          </div>
        </Flex>
      ),
    },
  ];

  return (
    <Card
      title="Daftar Permohonan"
      extra={(
        <Search
          placeholder="input search text"
          style={{ width: 200 }}
          css={`
            input {
              height: 36px;
            }
`}
        />
      )}
      style={{ padding: 24 }}
    >
      <SEO title="Permohonan" />
      {
        data && (
          <div>
            <Table css={tableNoBorder} loading={loading} dataSource={data} columns={columns} />
          </div>
        )
      }
      {
        loading && (
          <div>
            <Skeleton height={20} marginBottom={20} />
            <Skeleton height={20} marginBottom={20} />
            <Skeleton height={20} marginBottom={20} />
          </div>
        )
      }
      {
        data && !data.length ? (
          <Flex justifyContent="center" style={{ height: "50vh" }} alignItems="center">
            <div style={{ textAlign: "center" }}>
              <img src={doc} alt="" />
              <p style={{ marginTop: 20 }}>
                Belum ada data area untuk peruntukan “
                ”
              </p>
            </div>
          </Flex>
        ) : null
      }
    </Card>
  );
};

export default RequestList;
