import React, { useState } from "react";
import PropTypes from "prop-types";

export const RequestContext = React.createContext({});

const RequestContextProvider = ({ children }) => {
  const [expand, setExpand] = useState(false);
  const [idArea, setIdArea] = useState(null);
  const [coordinate, setCoordinate] = useState(null);
  const [properties, setProperties] = useState(null);

  return (
    <RequestContext.Provider value={{
      idArea,
      setIdArea,
      coordinate,
      setCoordinate,
      properties,
      setProperties,
      expand,
      setExpand,
    }}
    >
      {children}
    </RequestContext.Provider>
  );
};

RequestContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RequestContextProvider;
