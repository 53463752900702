import styled from "styled-components";
import { Input as InputAntd } from "antd";
import { maxSM } from "utils";

export const Input = styled(InputAntd)`
  &&& {
    background: #EFEFEF;
    &:not(:focus) {
      border: 1px solid transparent;
    }
  }
`;

export const InputPassword = styled(InputAntd.Password)`
  &&& {
    input {
      background: #EFEFEF;
      &:not(:focus) {
        border: 1px solid transparent;
      }
    }
  }
`;

export const CardStyled = styled.div`
  max-width: 480px;
  background: #fff;
  padding: 40px 60px;
  border-radius: 10px;
  ${maxSM} {
    padding: 40px 30px;
  }
`;

export const ForgotWrap = styled.div`
  margin-bottom: 30px;
  text-align: right;
  a {
    color: #333;
  }
`;

export const CardFooter = styled.div`
  margin-top: 20px;
  text-align: center;
  span {
    font-size: 14px;
    a {
      color: #168DE5;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
`;
