import styled from "styled-components";
import { Input, Radio, Form } from "antd";

export const RadioGroup = styled(Radio.Group)`
  width: 100%;
  .ant-radio-button-wrapper {
    width: 50%;
    text-align: center;
  }
`;

export const RadioDanger = styled(Radio.Button)`
  &&& {
    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background: #E91E63 !important;
      border-color: #E91E63 !important;
    }
  }
`;

export const Card = styled.div`
  background: #7AA28A27;
  padding: 10px;
  h4 {
    font-size: 16px;
  }
  &:not(:first-of-type) {
    margin-top: 20px;
  }
  ul {
    list-style: none;
    padding: 0;
    & > span {
      padding-bottom: 4px;
    }
    li {
      padding: 4px 0;
    }
    & > li {
      & > ul {
        & > li {
          & > div > span:first-of-type {
            padding-left: 20px;
          }
          & > span {
            padding-left: 20px;
          }
          & > ul {
            & > li {
              
              & > div > span:first-of-type {
                padding-left: 40px;
              }
            }
          }
        }
      }
    }
  }
`;

export const CarouselItem = styled.div`

`;

export const InputStyled = styled(Input)`
  &&& {
    border: 1px solid #585858;
    height: 36px;
  }
`;

export const Footer = styled.div`
  background: #fff;
  padding: 20px 0;

`;