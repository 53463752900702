import React from "react";
import styled from "styled-components";
import ucImg from "images/uc.png";

const UCStyled = styled.div`
  & > div {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    h3 {
      color: #4CAF50;
      font-size: 27px;
    }
  }
`;

const UnderConstruction = () => (
  <UCStyled>
    <div>
      <div style={{ textAlign: "center" }}>
        <img style={{ height: 320 }} src={ucImg} alt="" />
        <h3>Dalam tahap pengembangan</h3>
      </div>
    </div>
  </UCStyled>
);

export default UnderConstruction;
